<!-- 购物车页面购买数量过低提醒 -->
<template>
  <div>
    <DialogVue ref="DialogVueRef" :config="config">
      <div :style="`max-height: ${tableautoheight}px; overflow: auto`">
        <div class="paragraph">
          <h2>{{ $fanyi("我们不进行零售销售。") }}</h2>
          <p>
            {{ $fanyi("Rakumart是一个批发进口平台，不提供零售销售服务。") }}
          </p>
        </div>
        <div class="paragraph">
          <h2>{{ $fanyi("最小运输量") }}</h2>
          <p>
            {{
              $fanyi(
                "对于需要达到最低运输量的运输方式，订单总额必须达到或超过最低限制："
              )
            }}
          </p>
          <span>{{
            $fanyi("（陆运：13kg / 海运：10m³ / 铁路运输：21kg）")
          }}</span>
        </div>
        <div class="paragraph">
          <h2>{{ $fanyi("进口成本") }}</h2>
          <p>
            {{
              $fanyi(
                "少量进口货物或未达到最低运输量的进口货物可能会增加总成本，导致该操作不具盈利性。"
              )
            }}
          </p>
        </div>
      </div>
    </DialogVue>
  </div>
</template>
<script>
import DialogVue from "@/components/public/Dialog.vue";

export default {
  data() {
    return {
      config: {
        top: "10vh",
        width: "683px",
        title: this.$fanyi("关于订单数量的重要通知"),
        btnTxt: [this.$fanyi("理解了")],
      },
      tableautoheight: this.getHeight(),
    };
  },
  components: {
    DialogVue,
  },
  computed: {},
  created() {},
  mounted() {
    window.onresize = () => {
      this.tableautoheight = this.getHeight();
    };

    this.show = true;
  },
  methods: {
    getHeight() {
      return window.innerHeight > 500 ? window.innerHeight - 408 : 800;
    },
    open(operationEvent) {
      this.$refs.DialogVueRef.open("", operationEvent, () => {});
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.paragraph {
  line-height: 30px;
  margin: 0px 20px 40px;
  p {
    font-size: 20px;
    color: #5c5c5c;
    word-break: break-word !important;
  }
  h2 {
    font-size: 20px;
    font-weight: 700;
    color: #2b2b2b;
    word-break: break-word;
  }
  span {
    font-weight: 14px;
    word-break: break-word;
    color: #2b2b2b;
  }
}
/deep/.el-dialog__header {
  padding-left: 50px;
  font-size: 22px;
  color: #2b2b2b;
}
/deep/.el-dialog__body {
  padding-bottom: 0 !important;
}
/deep/.el-dialog__footer {
  padding-left: 50px;
  padding-bottom: 50px !important;
  > div {
    text-align: left !important;
  }
}
</style>
