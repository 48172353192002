<template>
  <div>
    <HomePageTop></HomePageTop>
    <div class="placingSuccessfullyPage">
      <div class="orderDetailBox">
        <div class="confirmation">
          <h1 class="confirmationH1">
            <div class="icon">
              <div class="green">
                <img :src="require('@/assets/icon/gougou.svg')" alt="" />
              </div>
            </div>
            {{ $fanyi("非常好！正在进行报价。") }}
          </h1>

          <p>
            {{
              $fanyi(
                "谢谢你。您的请求细节已成功确认。在不到24小时内，您将收到报价和订单付款细节的信息。您还将收到可能的运输方式的运输报价。"
              )
            }}
          </p>

          <el-button
            class="backHome"
            @click="$fun.routerToPage('/user/index')"
            type="primary"
            >{{ $fanyi("控制面板") }}</el-button
          >
        </div>
        <div class="alertaDeInformaci">
          <img :src="require('@/assets/icon/ibaioqian.png')" alt="" />
          <p>
            {{
              $fanyi(
                "符合条件的运输方式将取决于您的订单的特点(尺寸，产品的确切重量等)。如果订单没有超过任何运输方式的最小数量，它将出现在0。"
              )
            }}
          </p>
        </div>
        <div class="goodsBox" :class="{ GoodsArrAll: showGoodsArrAll }">
          <h1 @click="getBuyGoodsArr">
            {{ $fanyi("订单摘要") }}
            <span class="goodsBoxtishi"
              >{{ $fanyi("订单号") }}:{{ $parent.datas.order_sn }}</span
            >
          </h1>

          <div class="goodsArr">
            <div class="goodsHead goodsTr">
              <div class="goodsTd goodshe goodsName"></div>
              <div class="goodsTd goodshe goodsNumber">
                {{ $fanyi("购买金额") }}
              </div>
              <div class="goodsTd goodshe goodsPrice">
                {{ $fanyi("产品支出总额") }}
              </div>
            </div>
            <div
              class="goodsOpt goodsTr"
              v-for="goodsItem in buyGoodsArr"
              :key="'good' + goodsItem.id"
            >
              <div class="goodsTd goodsBo goodsName">
                <el-popover placement="right" trigger="hover">
                  <img
                    :src="goodsItem.pic"
                    alt=""
                    style="width: 300px; height: 300px"
                  />

                  <el-image slot="reference" :src="goodsItem.pic">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                    <div slot="placeholder">
                      <i class="el-icon-loading"></i>
                    </div>
                  </el-image>
                </el-popover>
                <el-popover placement="bottom" trigger="hover" width="800">
                  {{ goodsItem.goods_title }}
                  <span slot="reference" class="goodsTitle">
                    {{ goodsItem.goods_title }}
                  </span>
                </el-popover>
              </div>
              <div class="goodsTd goodsBo goodsNumber">
                {{ goodsItem.num }}
              </div>
              <div class="goodsTd goodsBo goodsPrice">
                <p>
                  {{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(
                        goodsItem.num *
                          goodsItem.price *
                          $parent.datas.exchange_rate
                      )
                    )
                  }}€
                </p>
                <p>
                  ({{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(goodsItem.num * goodsItem.price)
                    )
                  }}￥)
                </p>
              </div>
              <div class="henXian"></div>
            </div>
          </div>
          <div class="showAllRow">
            <img
              class="jiahao"
              src="../../../../../../assets/icon/jiahao_fill.svg"
              alt=""
              @click="showGoodsArrAll = !showGoodsArrAll"
              v-if="!showGoodsArrAll"
            />
            <img
              class="jiahao jian"
              src="../../../../../../assets/icon/jianhao.svg"
              alt=""
              @click="showGoodsArrAll = !showGoodsArrAll"
              v-else
            />
          </div>
        </div>
      </div>

      <div class="ortherBox">
        <div class="operatingPrinciple">
          <h1 class="operatingPrincipletishi">{{ $fanyi("下一步操作") }}:</h1>
          <div>
            <div class="tishiOpt">
              <div class="tihsiNum">1</div>
              <div class="tishiCon">
                <h1>{{ $fanyi("我们联系制造商") }}</h1>
                <div>
                  {{ $fanyi("我们联系制造商要求必要的信息和报价的订单。") }}
                </div>
              </div>
            </div>
            <div class="tishiOpt">
              <div class="tihsiNum">2</div>
              <div class="tishiCon">
                <h1>{{ $fanyi("你会在24小时内收到信息") }}</h1>
                <div>
                  {{
                    $fanyi(
                      "我们通知您所有类型的估计费用 海运(空运、海运和陆运)。"
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="tishiOpt">
              <div class="tihsiNum">3</div>
              <div class="tishiCon">
                <h1>{{ $fanyi("订单付款") }}</h1>
                <div>
                  {{
                    $fanyi(
                      "一旦订单报价，您可以通过银行转账或信用卡付款正式购买。"
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contactUs">
          <h1>{{ $fanyi("你有什么问题吗?") }}</h1>
          <p @click="tohref">
            <img src="../../../../../../assets/icon/xinFen2.png" alt="" />
            <span
              >{{ $fanyi("电子邮箱为") }}
              <font class="blueFont">soporte@rakumart.es</font></span
            >
            <img
              class="jiantou"
              src="../../../../../../assets/icon/jisntou2.png"
              alt=""
            />
          </p>
          <p @click="$fun.clickJs()">
            <img src="../../../../../../assets/icon/xiaoxi2.png" alt="" />
            <span>{{ $fanyi("使用聊天与我们沟通。") }}</span>
            <img
              class="jiantou"
              src="../../../../../../assets/icon/jisntou2.png"
              alt=""
            />
          </p>
          <p @click="$fun.clickJs()">
            <a href="https://wa.me/+8618868555265" target="_blank">
              <img src="@/assets/icon/dianhua2.png" alt="" />
              <span>{{ $fanyi("通过Whastapp给我们写信。") }} </span> </a
            ><img
              class="jiantou"
              src="../../../../../../assets/icon/jisntou2.png"
              alt=""
            />
          </p>
        </div>
      </div>
    </div>
    <div class="recommendGoodsMain">
      <nav>
        <h4>{{ $fanyi("给你的建议") }}</h4>
        <div class="iconBox">
          <img
            src="../../../../../../assets/icon/flecha-izquierda.svg"
            alt=""
            @click="shopCarouselChange('left')"
          />
          <img
            src="../../../../../../assets/icon/flecha-izquierda-1.svg"
            alt=""
            @click="shopCarouselChange('right')"
          />
        </div>
      </nav>
      <div>
        <el-carousel
          trigger="click"
          ref="shopCarousel"
          :interval="3000"
          :autoplay="autoplay"
          @mouseover.native="autoplay = false"
          @mouseleave.native="autoplay = true"
        >
          <el-carousel-item
            v-for="(item, index) in goodsList"
            :key="index"
            v-show="item.status"
          >
            <div class="recommendGoodsBox">
              <div
                v-for="(goodsItem, goodsIndex) in item"
                :key="goodsIndex"
                class="recommendGoods"
                @click="$fun.toCommodityDetails(goodsItem.goods_id)"
              >
                <div class="imageBox">
                  <el-image :src="goodsItem.imgUrl">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                    <div slot="placeholder">
                      <i class="el-icon-loading"></i>
                    </div>
                  </el-image>
                </div>
                <div class="goodsTitle" :title="goodsItem.title">
                  {{ goodsItem.title }}
                </div>
                <div class="priceBox">
                  <span class="priceBox">
                    <span>
                      {{
                        $fun.EURNumSegmentation(
                          $fun.ceil(
                            goodsItem.goodsPrice * $store.state.exchangeRate
                          )
                        )
                      }}€ </span
                    ><span>
                      {{ $fun.RMBNumSegmentation(goodsItem.goodsPrice) }}￥
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <FootVue></FootVue>
  </div>
</template>
<script>
import whyDiaVue from "./whyDia.vue";
import HomePageTop from "../../../../../../components/head/HomePageTop.vue";
import FootVue from "../../../../../../components/foot/Foot.vue";
export default {
  data() {
    return {
      buyGoodsArr: [],
      showGoodsArrAll: false,
      goodsList: [],
      autoplay: true,
      translation_name: "",
      chinese_name: "",
    };
  },
  components: { HomePageTop, whyDiaVue, FootVue },
  computed: {},
  created() {
    this.getBuyGoodsArr();
    this.getPersonalizedGoods();
  },
  methods: {
    tohref() {
      location.href = "mailto:soporte@rakumart.es";
    },
    chat(chat) {
      if (this.$store.state.userInfo) {
        let iTop = (window.screen.availHeight - 30 - 700) / 2;
        //获得窗口的水平位置
        let iLeft = (window.screen.availWidth - 10 - 1260) / 2;
        if (chat == 1) {
          window.open(
            "https://rakuchat1.rakumart.cn/im/client/#/?id=" +
              this.$store.state.userInfo.user_id,
            this.$store.state.userInfo,
            "height=700, width=1260, left=" +
              iLeft +
              ", top=" +
              iTop +
              ", toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no"
          );
        } else {
          window.open(
            this.chatLink ? this.chatLink : "https://wa.me/+8618868555265",
            "WhatsApp",
            "height=700, width=1260, left=" +
              iLeft +
              ", top=" +
              iTop +
              ", toolbar=no, menubar=no, scrollbars=yes, resizable=yes, location=no, status=no"
          );
        }
      } else {
        this.$store.commit(
          "getshowLoginDialog",
          !this.$store.state.showLoginDialog
        );
      }
    },
    //打开为什么装运是估计的弹窗
    openWhyDia() {
      this.$refs.whyDia.open();
    },
    // 获取推荐商品数据
    getPersonalizedGoods() {
      this.$api.getPersonalizedGoods().then((res) => {
        this.goodsList = res.data.result;
        this.chinese_name = res.data.chinese_name;
        this.translation_name = res.data.translation_name;
        this.ProcessingShopData();
      });
    },
    // 数据处理
    ProcessingShopData() {
      //console.log(23);
      let lack = 6 - (this.goodsList.length % 6); //取得店铺商品数离6个的倍数差多少个商品的数量
      //console.log("缺多少个商品", lack);
      //差多少个就从最前面取多少个填充到最后
      for (let i = 0; i < lack; i++) {
        this.goodsList.push(this.goodsList[i]);
      }
      //  分成六个一组
      this.goodsList = this.$fun.changeArr(this.goodsList, 6);
      //console.log(this.goodsList);
      this.$forceUpdate();
    },
    // 滑动店铺商品列表
    shopCarouselChange(type) {
      if (type == "left") {
        this.$refs.shopCarousel.prev();
      } else if (type == "right") {
        this.$refs.shopCarousel.next();
      }
    },
    getBuyGoodsArr() {
      //console.log(this.$parent.newList);
      let buydata = [];
      this.$parent.newList.forEach((shopsItem) => {
        shopsItem.list.forEach((goodsItem) => {
          buydata.push(goodsItem);
        });
      });
      this.buyGoodsArr = buydata;
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin";
.placingSuccessfullyPage {
  display: flex;
  align-items: flex-start;
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 40px;
  .orderDetailBox {
    .confirmation {
      width: 800px;
      // height: 454px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      padding: 40px 90px 20px;
      margin-bottom: 20px;
      .confirmationH1 {
        text-align: center;
        .icon {
          width: 109px;
          height: 108px;
          background: rgba($color: #bff1e3, $alpha: 0.2);
          border-radius: 50%;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          margin-bottom: 25px;
          .green {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 46px;
            height: 45px;
            background: #57bd9b;
            border-radius: 50%;
            img {
              width: 30px;
              height: 24px;
            }
          }
        }
        margin-bottom: 7px;
        font-size: 20px;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 23px;
      }
      p {
        width: 582px;
        font-size: 17px;
        font-weight: 400;
        color: #2f2f2f;
        line-height: 26px;
        margin: 0 auto 23px;
        text-align: center;
      }

      .backHome {
        width: 242px;
        height: 58px;
        background: #1c2899;
        border: 1px solid #e8e8e8;
        border-radius: 6px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 400;
        color: #ffffff;
        line-height: 16px;
        margin: 20px auto 20px;
        font-family: "Open Sans", Sans-serif;
      }
    }
    .alertaDeInformaci {
      width: 800px;
      background: #fffdf7;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      padding: 45px 0;
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 44px;
        height: 44px;
        margin-bottom: 24px;
      }
      p {
        width: 647px;
        margin: 0 auto;
        font-size: 17px;
        line-height: 26px;
      }
    }
    .goodsBox {
      width: 800px;
      max-height: 288px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      padding: 30px 0 0;
      position: relative;
      transition: 0.3s;
      overflow: hidden;
      .buyOrderSn {
        height: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        b {
          font-size: 16px;
          font-weight: 600;
        }
      }
      &.marginhave {
        margin-left: -40px;
        margin-top: -20px;
        margin-bottom: -20px;
        padding-top: 0;
        border: none;
        height: 222px;
        min-height: 222px;
      }

      h1 {
        padding: 0 40px;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 600;
        color: #2f2f2f;
        line-height: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .goodsBoxtishi {
          font-size: 16px;
          height: 17px;
          font-weight: 400;
          color: #2f2f2f;
          line-height: 17px;
        }
      }

      .goodsArr {
        padding: 0 40px;
        .goodsTr {
          display: flex;
          align-items: center;
          // 循环的商品行
          &.goodsOpt {
            position: relative;
            padding: 8px 0 12px;
            .henXian {
              width: 100%;
              height: 6px;
              background: #dfdfdf;
              border-radius: 3px;
              position: absolute;
              left: 0;
              bottom: 0px;
            }
          }
          // 所有单元格样式
          .goodsTd {
            font-size: 13px;
            font-weight: 400;
            color: #2f2f2f;
            line-height: 29px;
            flex: 1;
            // 所有单元格标题样式
            &.goodshe {
              margin-top: 17px;
              font-size: 13px;
              line-height: 1;
              font-weight: bold;
              background-color: white;
              display: flex;
              align-items: flex-end;
            }
            // 所有单元格内容样式
            &.goodsBo {
              height: 55px;
            }

            // 单个单元格样式》》》
            // 价格单元格样式
            &.goodsPrice {
              flex: 0 0 164px;
              margin-left: 50px;
              &.goodshe {
                display: flex;
                justify-content: center;
                align-items: center;
              }

              &.goodsBo {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                line-height: 1;
                .eu {
                  font-size: 16px;
                  font-weight: 600;
                }
              }

              .mingXi {
                display: block;
                font-size: 12px;
                text-align: center;
                color: #888888;
                line-height: 16px;
                cursor: pointer;
              }
            }
            // 数量单元格样式
            &.goodsNumber {
              border-color: rebeccapurple;
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 0 0 150px;

              &.goodsBo {
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                text-align: center;
              }
            }
            // 商铺单元格样式
            &.goodsName {
              display: flex;
              /deep/.el-image {
                $imgSize: 55px;
                width: $imgSize;
                height: $imgSize;
                transition: 0.3s;
                text-align: center;
                i {
                  font-size: $imgSize/4;
                  line-height: $imgSize;
                }
              }

              .goodsTitle {
                padding: 0 30px;
                padding-top: 4px;
                height: 55px;
                font-size: 12px;
                font-weight: 400;
                position: relative;
                top: -5px;
                color: #2f2f2f;
                line-height: 22px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }

      .showAllRow {
        height: 48px;
        background: #ffffff;
        border: 1px solid #e3e3e3;
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 4px 4px;
        position: sticky;
        bottom: 0;
        width: 100%;
        left: 0;
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .jiahao {
          width: 24px;
          height: 24px;
          margin-right: 32px;
          cursor: pointer;
        }
      }

      &.GoodsArrAll {
        // min-height: 392px;
        max-height: unset !important;
      }
    }
  }
  .ortherBox {
    margin-left: 40px;
    .operatingPrinciple {
      padding: 57px 30px 40px;
      margin-bottom: 20px;
      width: 560px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      .operatingPrincipletishi {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 600;
        color: #2f2f2f;
        line-height: 18px;
        font-family: "Open Sans", Sans-serif;
      }
      .tishiOpt {
        height: 119px;
        border-bottom: solid 1px #e7e7e7;
        display: flex;
        align-items: flex-start;
        padding: 30px 0;
        .tihsiNum {
          flex: 0 0 41px;
          height: 41px;
          background: #f7f7f7;
          border: 1px solid #e7e7e7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        }
        .tishiCon {
          h1 {
            font-size: 16px;
            font-family: "Open Sans", Sans-serif;
            font-weight: 400;
            color: #21213b;
            line-height: 14px;
            margin-bottom: 6px;
          }
          > div {
            height: 35px;
            font-size: 14px;
            font-family: "Open Sans", Sans-serif;
            font-weight: 400;
            line-height: 21px;

            color: #66667e;
          }
        }
      }
      .whyIsYuGu {
        margin-top: 29px;
        display: flex;
        height: 18px;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 13px;
          font-weight: 400;
          text-decoration: underline;
          color: #1c2899;
          line-height: 20px;
        }
        img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
      }
    }
    .contactUs {
      width: 560px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      padding: 40px;

      h1 {
        font-size: 18px;
        font-weight: 600;
        color: #2f2f2f;
        line-height: 18px;
        margin-bottom: 30px;
      }
      p {
        width: 505px;
        height: 74px;
        background: #ffffff;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        padding: 0 10px 0 20px;
        cursor: pointer;
        &:hover {
          background-color: #f6f6f6;
        }
        img {
          width: 18px;
          margin-right: 9px;
        }
        span {
          font-size: 16px;
          line-height: 16px;
          color: #222222;
          .blueFont {
          }
        }
        a {
          display: flex;
          align-items: center;
        }
        .jiantou {
          margin-left: auto;
          width: 8px;
        }
      }
    }
  }
}
.recommendGoodsMain {
  width: $pageWidth;
  margin: 0 auto;
  padding-bottom: 40px;
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h4 {
      font-size: 24px;
    }
    .iconBox {
      img {
        width: 40.88px;
        height: 40.88px;
        &:last-child {
          margin-left: 20px;
          margin-right: 30px;
        }
        opacity: 0.45;
        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
  .recommendGoodsBox {
    display: flex;
    .recommendGoods {
      cursor: pointer;
      width: 213.31px;
      padding: 15px;
      height: 290px;
      background-color: white;
      border: #e8e8e8 solid 1px;
      border-radius: 6px;
      margin: 10px;
      transition: 0.3s;
      .imageBox {
        width: 100%;
        height: 187.31px;
        margin-bottom: 5px;
        text-align: center;
        /deep/.el-image {
          $imgSize: 181.31px;
          width: $imgSize;
          height: $imgSize;
          transition: 0.3s;
          text-align: center;
          i {
            font-size: $imgSize/4;
            line-height: $imgSize;
          }
        }
      }
      .goodsTitle {
        height: 39px;

        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
        color: #404040;
        font-family: "Open Sans", Sans-serif;
        font-size: 13px;
        font-weight: 500;
      }
      .priceBox {
        color: #ffa200;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6em;
      }
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 0px 15px rgb(223, 223, 223);
      }
    }
  }
}
/deep/.el-carousel__arrow--right,
/deep/.el-carousel__arrow--left {
  display: none;
  width: 100px;
  height: 100px;
  background: #ffffff;
  opacity: 0.6;
  border-radius: 50%;
}
/deep/ .el-carousel__arrow {
  background: rgba(255, 255, 255, 0.5);

  i {
    color: $homePageSubjectColor;
    font-size: 60px;
    font-weight: 700;
  }
}
/deep/.el-carousel__button {
  display: none;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
}
/deep/.el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  outline: 3px solid $homePageSubjectColor;
  background-color: transparent;
}
</style>
