import Vue from "vue";
import fanyi from "@/utlis/language.js";
import fun from "@/utlis/CommonlyUsedFunctions.js";
// import Vuex from 'vuex'
// Vue.use(Vuex)

let ps = {
  // --------------------------------------------data----------------------
  step: 1, //大的分页
  formregister: { market_channel: [], country_phone: "+34" },
  inputRequireVer: {
    required: true,
    message: fanyi("此字段为必填项"),
    trigger: "blur",
  },

  Countdown: 60,
  // --------------------------------------------ref-----------------------
  PersonalDetails: null,
  DetailsOfTheCompany: null,
  RakumartExperience: null,
  Ocheck: null,
  VerificationCode: null,
  WelcomeVideo: null,
  registerBodyCarouselRef: null,
  //---------------------------------------------methods-------------------
  backLastPage() {
    if (ps.step == 1) {
      ps.PersonalDetails.goBack();
    } else if (ps.step == 2) {
      ps.DetailsOfTheCompany.goBack();
    } else if (ps.step == 3) {
      ps.RakumartExperience.goBack();
    } else if (ps.step == 4) {
      ps.Ocheck.goBack();
    } else if (ps.step == 5) {
      ps.VerificationCode.goBack();
    } else if (ps.step == 6) {
      ps.WelcomeVideo.goBack();
    }
  },
  goNext() {
    // ps.registerBodyCarouselRef.setActiveItem(ps.step)
    // console.log(ps.step);
    if (ps.step != 5) {
      ps.step++;
    } else {
      fun.routerToPage("/register-success");
    }
  },
};

export default ps;
