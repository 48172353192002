<template>
  <div>
    <div class="diaBox">
      <DialogVue ref="dialog" :config="config">
        <h1 class="xiuGaiDiZhi">{{ $fanyi("修改收货地址") }}</h1>
        <div class="xiugaiForm">
          <dizhiForm ref="shouhuodizhiForm"></dizhiForm>
        </div>
        <div class="footBtn">
          <button @click="saveChangeAddress('shouhuodizhi')">
            {{ $fanyi("保存") }}
          </button>
          <button @click="closeDia('dialog')">{{ $fanyi("取消") }}</button>
        </div>
      </DialogVue>
      <DialogVue ref="dialo2" :config="config">
        <h1 class="xiuGaiDiZhi">{{ $fanyi("进口商联系人") }}</h1>
        <div class="xiugaiForm">
          <jinkoushangForm ref="jinkoushangFormodd"></jinkoushangForm>
        </div>
        <div class="footBtn">
          <button @click="saveChangeAddress('jinkoushang')">
            {{ $fanyi("保存") }}
          </button>
          <button @click="closeDia('dialo2')">{{ $fanyi("取消") }}</button>
        </div>
      </DialogVue>
      <DialogVue ref="dialo3" :config="config">
        <h1 class="xiuGaiDiZhi">{{ $fanyi("提示") }}</h1>
        <div class="tiShiWenZi">
          {{ $fanyi("请联系客服修改配送单地址") }}
        </div>
        <div class="footBtn">
          <button @click="closeDia('dialo3')">
            {{ $fanyi("已理解") }}
          </button>
          <!-- <button @click="closeDia('dialo3')">{{ $fanyi("取消") }}</button> -->
        </div>
      </DialogVue>
    </div>

    <el-button
      type="primary"
      class="xiuGaiShuJu"
      @click="xiugaidizhi"
      v-if="isPeiSongDan"
    >
      {{ $fanyi("修改数据") }}
    </el-button>
    <el-button type="primary" class="xiuGaiShuJu" @click="openTiShi" v-else>
      {{ $fanyi("修改数据") }}
    </el-button>
  </div>
</template>
<script>
import DialogVue from "../../../components/public/Dialog.vue";
import dizhiForm from "./dizhiForm.vue";
import jinkoushangForm from "./jinkoushangForm.vue";
export default {
  props: {
    xiuGaiLeiXin: {
      default: "dizhi",
      type: String,
    },
  },
  data() {
    return {
      jiancha: true,
      config: {
        top: "20vh",
        width: "803px",
        title: "提示",
        btnTxt: ["删除", "取消"],
      },
    };
  },
  components: {
    DialogVue,
    dizhiForm,
    jinkoushangForm,
  },
  computed: {
    //是否是配送单
    isPeiSongDan() {
      return !this.$parent.$parent.isPeiSongDan;
    }, //收货地址
    shouhuodizhi() {
      return JSON.parse(
        JSON.stringify(
          this.$parent.$parent.goodsdatas.orderAddress.consignee_address
        )
      );
    }, //进口商
    jinkoushang() {
      return JSON.parse(
        JSON.stringify(
          this.$parent.$parent.goodsdatas.orderAddress.importer_address
        )
      );
    },
  },
  mounted() {},
  methods: {
    // 清除地址栏数据里空的选项
    clearUserAddressNullInfo() {
      for (let shouhuoI in this.shouhuodizhi) {
        if (
          this.shouhuodizhi[shouhuoI] == null ||
          this.shouhuodizhi[shouhuoI] == ""
        ) {
          delete this.shouhuodizhi[shouhuoI];
        }
      }
      for (let jinkouI in this.jinkoushang) {
        if (
          this.jinkoushang[jinkouI] == null ||
          this.jinkoushang[jinkouI] == ""
        ) {
          delete this.jinkoushang[jinkouI];
        }
      }
    },
    saveChangeAddress(type) {
      this.$parent.$parent.payLoading = true;
      this.clearUserAddressNullInfo();
      //console.log("删除后数据", this.shouhuodizhi, this.jinkoushang);

      // 判断传参创建传递给接口的参数
      if (type == "jinkoushang") {
        var dataname = "jinkoushang";
        var typename = "importer_address_id";
      } else {
        var dataname = "shouhuodizhi";
        var typename = "consignee_address_id";
      }
      this.$api.addressStore(this[dataname]).then((res) => {
        if (res.code != 0) {
          this.$parent.$parent.payLoading = false;
          return this.$message.error(this.$fanyi(res.msg));
        }
        this.$api
          .updateOrderAddressId({
            order_sn: this.$route.query.order_sn,
            consignee_address_id:
              typename == "consignee_address_id"
                ? res.data
                : this.shouhuodizhi.id,
            importer_address_id:
              typename == "importer_address_id"
                ? res.data
                : this.jinkoushang.id,
          })
          .then((resdd) => {
            if (resdd.code != 0) {
              this.$parent.$parent.payLoading = false;
              return this.$message.error(this.$fanyi("resdd.msg"));
            }
            this.$parent.$parent.getgoodsData(
              this.$route.query.order_sn,
              () => {
                if (this.xiuGaiLeiXin == "jinkoushang") {
                  this.$refs.dialo2.cancel();
                } else {
                  this.$refs.dialog.cancel();
                }
                this.$parent.$parent.payLoading = false;
              }
            );
          });
      });
    },
    // 打开提示弹窗，配送单页面不能修改地址
    openTiShi() {
      this.$refs.dialo3.open(
        "",
        () => {},
        () => {}
      );
    },
    // 关闭弹窗
    closeDia(id) {
      this.$refs[id].cancel();
    },
    // 打开修改地址弹窗
    xiugaidizhi() {
      // 假如修改类型是联系人就打开弹窗2
      if (this.xiuGaiLeiXin == "jinkoushang") {
        this.$refs.dialo2.open(
          "",
          () => {},
          () => {}
        );
      } else {
        this.$refs.dialog.open(
          "",
          () => {},
          () => {}
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../css/mixin.scss";
.diaBox {
  position: fixed;
  left: 0;
  top: 0;
}
/deep/.el-dialog__header {
  display: none;
}
/deep/.el-dialog__footer {
  display: none;
}
/deep/.el-dialog__body {
  padding: 40px 23px 40px 40px !important;
}
.xiuGaiDiZhi {
  height: 18px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 36px;
}
.xiugaiForm {
}
.footBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  button {
    min-width: 117px;
    height: 50px;
    padding: 0 29px;
    color: white;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:last-child {
      margin-left: 20px;
      background: #bd4747;
    }
    &:first-child {
      background: #1c2899;
    }
  }
}
.xiuGaiShuJu {
  width: 160px;
  height: 36px;
  background: #1c2899;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tiShiWenZi {
  font-size: 25px;
  text-align: center;
  margin-bottom: 50px;
}
</style>
