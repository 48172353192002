var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"userOrder"}},[_c('Dialog',{ref:"dialog",attrs:{"config":_vm.config}}),_c('div',[_c('div',{staticClass:"searchBox"},[_c('div',{staticClass:"myInput"},[_c('i',{staticClass:"el-icon-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.searchText),expression:"form.searchText"}],attrs:{"type":"text","placeholder":_vm.$fanyi('请输入关键词搜索')},domProps:{"value":(_vm.form.searchText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getData()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "searchText", $event.target.value)}}})]),_c('button',{staticClass:"searchBtn",on:{"click":function($event){_vm.form.page = 1;
          _vm.getData(_vm.form);}}},[_vm._v(" "+_vm._s(_vm.$fanyi("搜索"))+" ")])]),_c('div',{staticClass:"dataBody"},[_c('el-table',{key:_vm.tableKey,staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","header-cell-style":{
          'text-align': 'center',
          background: '#F0F0F0',
          color: 'black',
          'font-size': '12px',
          'font-weight': '500',
        },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"type":"index","width":"50","label":"Nº"}}),_c('el-table-column',{attrs:{"prop":"name","label":_vm.$fanyi('配送单号'),"width":"190"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('button',{staticClass:"porder_sn",on:{"click":function($event){return _vm.$fun.routerToPage(
                  '/deliveryDetails?order_sn=' + scope.row.porder_sn
                )}}},[_vm._v(" "+_vm._s(scope.row.porder_sn)+" ")])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"tableHeader",attrs:{"title":_vm.$fanyi('配送单号')}},[_vm._v(" "+_vm._s(_vm.$fanyi("配送单号"))+" ")])])],2),_c('el-table-column',{attrs:{"label":_vm.$fanyi('西班牙物流'),"width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',_vm._l((scope.row.porder_foreign_express),function(item,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(item.foreign_express)+": "),_c('span',{staticStyle:{"color":"#ffa724"}},[_vm._v(_vm._s(item.foreign_express_no))])])}),0)]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"tableHeader",attrs:{"title":_vm.$fanyi('西班牙物流')}},[_vm._v(" "+_vm._s(_vm.$fanyi("西班牙物流"))+" ")])])],2),_c('el-table-column',{attrs:{"label":_vm.$fanyi('签收时间'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.porder_time_node != null &&
                  scope.row.porder_time_node.datetime_client_receive != null
              )?_c('div',[_vm._v(" "+_vm._s(scope.row.porder_time_node.datetime_client_receive)+" ")]):_vm._e()]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"tableHeader",attrs:{"title":_vm.$fanyi('签收时间')}},[_vm._v(" "+_vm._s(_vm.$fanyi("签收时间"))+" ")])])],2),_c('el-table-column',{attrs:{"label":_vm.$fanyi('海关'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.porder_time_node != null &&
                  scope.row.porder_time_node.datetime_cleared != null
              )?_c('div',[_vm._v(" "+_vm._s(scope.row.porder_time_node.datetime_cleared)+" ")]):_vm._e()]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"tableHeader",attrs:{"title":_vm.$fanyi('海关')}},[_vm._v(" "+_vm._s(_vm.$fanyi("海关"))+" ")])])],2),_c('el-table-column',{attrs:{"prop":"logistics","label":_vm.$fanyi('国际运输方式'),"width":"159"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(_vm.$fanyi(scope.row.status_name))+" ")])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"tableHeader",attrs:{"title":_vm.$fanyi('国际运输方式')}},[_vm._v(" "+_vm._s(_vm.$fanyi("国际运输方式"))+" ")])])],2),_c('el-table-column',{attrs:{"label":_vm.$fanyi('箱数'),"width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(_vm._s(scope.row.porder_freight.length))])]}}])}),_c('el-table-column',{attrs:{"prop":"billing_parameter","label":_vm.$fanyi('体积') + '(m³)',"width":"80"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"tableHeader",attrs:{"title":_vm.$fanyi('体积') + '(m³)'}},[_vm._v(" "+_vm._s(_vm.$fanyi("体积") + "(m³)")+" ")])])],2)],1)],1),_c('div',{staticClass:"allpag"},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.form.page = 1;
          _vm.getData();}}},[_vm._v(_vm._s(_vm.$fanyi("回到首页")))]),_c('el-pagination',{attrs:{"current-page":_vm.form.page,"page-size":10,"layout":"total, prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }