let data = [
  { router: "centro-de-ayuda", title: "Centro de ayuda" },
  "empieza-en-rakumart",
  "mi-cuenta-3",
  "hacer-un-pedido",
  "pagos",
  "logistica-y-envio",
  "garantias-y-posventa",
  "que-hace-rakumart",
  "que-es-rakumart",
  "como-funciona-rakumart",
  "que-ventajas-ofrece-rakumart",
  "a-quien-ofreceis-vuestros-servicios",
  "servicios-adicionales-para-productos",
  "servicios-de-personalizacion-y-marca-privada",
  "como-me-registro",
  "por-que-no-puedo-iniciar-sesion-en-mi-cuenta",
  "como-cambio-los-detalles-de-mi-cuenta",
  "como-editar-o-restablecer-mi-contrasena",
  "donde-puedo-descargar-mis-facturas",
  "como-puedo-modificar-los-datos-de-facturacion",
  "como-puedo-anadir-o-editar-mi-direccion-de-envio",
  "donde-puedo-encontrar-los-cupones-que-obtengo",
  "como-usar-un-cupon",
  "necesito-una-cuenta-para-hacer-un-pedido",
  "como-hacer-un-pedido",
  "puedo-comprar-muestras",
  "cuando-recibo-la-cotizacion",
  "como-consultar-el-estado-de-mi-pedido",
  "que-significa-pedido-pendiente-de-cotizacion",
  "que-significa-pedido-de-productos-pendiente-de-pago",
  "que-significa-envio-internacional-pendiente-de-pago",
  "hay-cantidad-minima-de-compra",
  "puedo-hacer-cambios-en-el-pedido-que-hice",
  "por-que-no-recibi-un-correo-electronico-de-confirmacion-sobre-mi-pedido",
  "cual-es-el-significado-de-los-diferentes-estados-de-pedido",
  "como-agregar-articulos-a-un-pedido-confirmado",
  "como-cancelar-el-pedido",
  "proceso-de-pagos",
  "cuando-se-pagan-los-productos",
  "como-puedo-realizar-el-pago-de-los-productos",
  "cuando-se-paga-el-envio",
  "como-puedo-realizar-el-pago-del-envio",
  "cuales-son-las-tarifas-de-la-compra-de-productos",
  "debo-pagar-otras-tarifas-cuando-llegue-el-pedido",
  "debo-pagar-el-control-de-calidad",
  "que-tipo-de-pagos-se-aceptan",
  "que-divisas-puedo-usar",
  "cual-es-el-coste-del-envio",
  "la-entrega-del-envio-incluye-ddp-delivery-duty-paid",
  "cuanto-tardare-en-recibir-mi-pedido",
  "donde-esta-mi-pedido",
  "cuando-puedo-obtener-el-numero-de-seguimiento",
  "cotizacion-estimada-de-envio",
  "desglose-de-costes-de-envio",
  "transporte-de-mercancias-peligrosas",
  "seleccion-de-empresas-de-transporte-internacional",
  "seleccion-de-empresas-de-transporte-internacional",
  "despacho-de-las-mercancias-en-aduanas",
  "donde-enviais",
  "por-que-no-recibi-un-correo-electronico-sobre-el-envio-de-mi-pedido",
  "por-que-se-retrasa-mi-pedido",
  "por-que-no-hay-actualizacion-de-seguimiento",
  "cuales-son-las-diferencias-entre-los-tres-tipos-de-envio-disponibles",
  "puedo-enviar-mi-pedido-directamente-a-las-bodegas-de-amazon",
  "que-garantias-ofrece-rakumart",
  "politica-de-muestras-en-rakumart",
  "haceis-control-de-calidad-de-la-mercancia",
  "que-pasa-si-los-productos-no-superan-el-control-de-calidad",
  "como-verificais-a-los-proveedores",
  "los-productos-cuentan-con-los-certificados-necesarios-para-cumplir-con-las-normativas-de-europa",
  "que-pasa-si-recibi-un-articulo-defectuoso",
  "que-pasa-si-el-pedido-llega-en-mal-estado-a-espana",
  "cual-es-su-politica-de-devolucion-y-reembolso",
  "contraband",
  "norm-de-adquisicion-de-peso-de-facturacion-logistica",
];

export default data;
