import { apiAxios } from "../axios";

import Europess from "../wangZhi";
let Europe = Europess + "/client/";
export default {
  // 收集用户访问的url
  saveUserRequest: (data) =>
    apiAxios("post", Europe + "data.saveUserRequest", data),
  // 替换商品删除此番订单详情
  replaceGoodsDeleteOrderDetail: (data) =>
    apiAxios("post", Europe + "orderDetailDelete", data),
  // 替换商品还原
  replaceGoodsRestore: (data) =>
    apiAxios("post", Europe + "replaceGoodsRestore", data),
  // 取消替换链接
  replaceStatusSave: (data) =>
    apiAxios("post", Europe + "replaceStatusSave", data),
  // 订单支付后查询
  orderAfterPayment: (data) =>
    apiAxios("post", Europe + "orderAfterPayment", data),
  // 国际物流轨迹-查询
  getPorderExpressTrace: (data) =>
    apiAxios(
      "post",
      Europe + "internationalLogistics.getPorderExpressTrace",
      data
    ),
  // 计算国际运费费用
  calculationLogisticsAmount: (data) =>
    apiAxios(
      "post",
      Europe + "internationalLogistics.calculationLogisticsAmount",
      data
    ),
  // 国际费用说明
  logisticsAmountExplain: (data) =>
    apiAxios(
      "post",
      Europe + "internationalLogistics.logisticsAmountExplain",
      data
    ),
};
