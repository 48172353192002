<template>
  <div class="quienes-somos">
    <HomePageTopVue></HomePageTopVue>
    <div class="headBox">
      <div class="headCon">
        <h1>Cambiamos la manera de importar</h1>
        <p>
          Rakumart es la plataforma de importación todo en uno donde gestionamos
          por ti todo el proceso de compra, logística e importación.
        </p>
        <button class="registerButton" @click="$fun.routerToPage('/register')">
          Regístrate ahora
        </button>
      </div>
      <div class="videoBox">
        <div
          class="edy-texteditor-container embed-container edy-positionable-container-maxwidth"
          contenteditable="false"
          unselectable="on"
          style="
            max-width: 100%;
            overflow: hidden;
            position: relative;
            width: auto;
          "
        >
          <div
            class="edy-padding-resizer-wrapper"
            style="padding-bottom: 56.25%"
          >
            <iframe
              id="ifrId"
              src="//www.youtube.com/embed/Lo8E7vnnMuU?wmode=transparent"
              frameborder="0"
              style="
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
              "
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="aboutCon">
      <div class="pageBox">
        <div class="abCon">
          <div class="row towModBox">
            <img
              src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/64215b566d7a9.png"
              alt=""
            />
            <div class="fontCon">
              <h3>
                La solución más potente para el comercio electrónico B2B desde
                China.
              </h3>
              <p>
                Rakumart es una empresa B2B cuyo objetivo principal es ayudar a
                los negocios españoles a importar productos desde China de la
                forma más económica y eficiente, eliminando costes, procesos y
                dando al cliente la mejor experiencia de importación.
              </p>
              <p>
                Con sede central ubicada en Yiwu, China, contamos con un equipo
                independiente de investigación y desarrollo tecnológico, con más
                de 2.500m2 de espacio operativo en oficinas, 70.000m2 de almacén
                en las empresas de nuestro grupo y más de 220 empleados en
                plantilla.
              </p>
              <div class="blueBorderBox">
                <div class="fontBox oficinas">
                  <p class="fontTitle">+2.500m2</p>
                  <p class="fontConBox">de oficinas</p>
                </div>
                <div class="fontBox almacenes">
                  <p class="fontTitle">+70.000m2</p>
                  <p class="fontConBox">almacenes</p>
                </div>
                <div class="fontBox empleados">
                  <p class="fontTitle">+220</p>
                  <p class="fontConBox">empleados</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row streamlineTheProcess">
            <div class="streamCon">
              <div class="streamConleft">
                <h3>Simplifica tus compras e importaciones desde China</h3>
                <p>
                  Nuestra plataforma permite que los usuarios españoles puedan
                  disfrutar de un fácil acceso a servicios de importación todo
                  en uno, que van desde la compra, la realización de pedidos, la
                  inspección de calidad, el envío internacional y la posventa en
                  idioma español.
                </p>
                <button @click="$fun.routerToPage('/register')">
                  Regístrate ahora
                </button>
              </div>
              <ul class="streamConRight">
                <li>
                  <i class="listIcon"></i>
                  <span
                    >Reduce los costes en producto y aumenta la rentabilidad de
                    tu negocio</span
                  >
                </li>
                <li>
                  <i class="listIcon"></i>
                  <span
                    >Nos encargamos de toda la gestión documentaria, trámites y
                    procesos aduaneros</span
                  >
                </li>
                <li>
                  <i class="listIcon"></i>
                  <span
                    >Infraestructura en China y España para sacarle provecho con
                    tu negocio</span
                  >
                </li>
                <li>
                  <i class="listIcon"></i>
                  <span
                    >Acompañamiento, asesoramiento, servicio de atención al
                    cliente y posventa en español</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="row towModBox">
            <div class="fontCon">
              <h3>
                Encuentra todo lo que buscas, la fábrica del mundo en tus manos.
              </h3>
              <p>
                Actualmente, los usuarios de Rakumart pueden encontrar cualquier
                producto disponible en las principales plataformas chinas de
                comercio electrónico.
              </p>
              <p>
                Nuestra amplia red de colaboradores permite que podamos
                ofrecerte una inmensa oferta de productos y más de 100.000
                proveedores chinos de alta calidad con los que trabajamos a
                diario en nuestras diferentes sedes alrededor del mundo.
              </p>
              <div class="blueBorderBox">
                <div class="fontBox">
                  <p class="fontTitle">Millones</p>
                  <p class="fontConBox">de productos disponibles</p>
                </div>
                <div class="fontBox">
                  <p class="fontTitle">+100.000</p>
                  <p class="fontConBox">proveedores verificados</p>
                </div>
              </div>
            </div>
            <img
              src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/64224a14c370b.png"
              alt=""
            />
          </div>
          <div class="row useTheAdvantage">
            <div class="helpMakeMoney">
              <div class="helpMakeMoneyLeft">
                <h3>Ayudamos a tu negocio a ser más rentable</h3>
                <button
                  class="registerButton"
                  @click="$fun.routerToPage('/register')"
                >
                  Regístrate ahora
                </button>
              </div>
              <div class="helpMakeMoneyRight">
                <p>
                  Rakumart permite abastecer tu negocio en el mercado mayorista
                  más grande del mundo. Nos encargamos de todo el proceso, desde
                  la fábrica hasta tu puerta, lo que aumenta la rentabilidad y
                  el tiempo disponible para tu negocio.
                </p>
              </div>
            </div>
            <div class="logisticalAdvantages">
              <div class="paddingBox">
                <div class="logisticalAdvantagesOpt">
                  <img
                    src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/642255dcd531b.png"
                    alt=""
                  />
                  <p class="boxTitle">
                    Gestión completa y fácil de importaciones desde China
                  </p>
                  <p class="boxCon">
                    Nos encargamos de toda la gestión documentaria, trámites y
                    procesos aduaneros.
                  </p>
                </div>
              </div>
              <div class="paddingBox">
                <div class="logisticalAdvantagesOpt">
                  <img
                    src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/642255edbb5cd.png"
                    alt=""
                  />
                  <p class="boxTitle">Optimiza costes de envío e importación</p>
                  <p class="boxCon">
                    Agrupamos mercancías de distintos proveedores y reunificamos
                    envíos internacionales.
                  </p>
                </div>
              </div>
              <div class="paddingBox">
                <div class="logisticalAdvantagesOpt">
                  <img
                    src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/64225600f3d8c.png"
                    alt=""
                  />
                  <p class="boxTitle">
                    Infraestructura logística para sacarle provecho con tu
                    negocio.
                  </p>
                  <p class="boxCon">
                    Contamos con almacenes propios en China a tu disposición.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="imgRow">
      <div class="imgBox">
        <div class="imgRowconBox">
          <div class="h1title">
            Presentes en tres continentes, nuestro objetivo es cambiar el mundo
            de los negocios con China.
          </div>
          <p>
            Actualmente Rakumart opera en tres continentes cuyas empresas
            afiliadas están situadas en , Osaka (Japón), Seúl (Corea de Sur),
            Sao Paulo (Brasil), Hong Kong y Hangzhou (China).
          </p>
          <p>
            Queremos abarcar los principales mercados lingüísticos mundiales,
            aprovechar el espacio del comercio digital B2B desde China, abrir
            nuevos mercados en diferentes países y permitir que más negocios
            disfruten de los beneficios del «comercio digital inclusivo» que
            ofrece Rakumart.
          </p>
        </div>
      </div>
    </div>
    <FootVue></FootVue>
  </div>
</template>
<script>
import FootVue from "../../components/foot/Foot.vue";
import HomePageTopVue from "../../components/head/HomePageTop.vue";
export default {
  data() {
    return {};
  },
  components: { HomePageTopVue, FootVue },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style></style>
<style lang="scss" scoped="scoped">
@import "../../css/mixin.scss";
.quienes-somos {
  width: 100%;
  min-width: 1400px;
  background-color: #fff;
}
.registerButton {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  background-color: #1a289d;
  border-radius: 50px 50px 50px 50px;
  padding: 20px 40px;
  color: white;
}
.headBox {
  margin-top: -20px;
  height: 604px;
  background-image: linear-gradient(61deg, #fff2f0 0%, #dce5fd 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  .headCon {
    width: 516px;
    padding: 10px;

    h1 {
      color: #000000;
      font-size: 45px;
      font-weight: 700;
      line-height: 50.4px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 34px;
      font-size: 18px;
      font-weight: 400;
      line-height: 33px;
    }
  }
  .videoBox {
    margin: 0;
    width: 684px;
    height: 384px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 18px 22px -7px rgba(0, 0, 0, 0.08);
  }
}

.aboutCon {
  margin: 0 auto;
  .pageBox {
    background-color: white;
    .abCon {
      .row {
      }
      .towModBox {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        img {
          width: 600px;
        }
        .fontCon {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 20px;
          h3 {
            font-size: 31px;
            font-weight: 600;
            line-height: 1.4em;
            margin-bottom: 20px;
          }
          p {
            font-size: 18px;
            font-weight: 400;
            line-height: 33px;
            margin-bottom: 14.4px;
          }
          .blueBorderBox {
            display: flex;
            .fontBox {
              flex: 1;
              border-left: 5px solid #1c46af;
              padding-left: 20px;
              height: 80px;
              display: flex;
              justify-content: center;
              flex-direction: column;
              .fontTitle {
                font-size: 28px;
                line-height: 1.4em;
                font-weight: 600;
                margin-bottom: 0;
              }
              .fontConBox {
                margin-bottom: 0px;
                font-size: 18px;
                font-weight: 400;
                line-height: 33px;
              }
            }
            .oficinas {
              flex: 0 0 207px;
            }
            .almacenes {
              flex: 0 0 200px;
            }
          }
        }
      }
      .streamlineTheProcess {
        width: 100%;
        background-color: #000423;
        height: 620px;
        display: flex;
        justify-content: center;
        align-items: center;
        .streamCon {
          width: 1200px;
          display: flex;
          justify-content: center;
          .streamConleft {
            width: 626px;
            padding: 20px;
            h3 {
              color: #ffffff;
              font-size: 41px;
              font-weight: 700;
              line-height: 1.2;
              margin-bottom: 20px;
            }
            p {
              color: #ffffff;
              font-size: 18px;
              font-weight: 400;
              line-height: 33px;
              margin-bottom: 34px;
            }
            button {
              font-size: 16px;
              font-weight: 500;
              background-color: #ffffff;
              border-radius: 50px 50px 50px 50px;
              padding: 20px 40px;
              font-size: 16px;
              font-weight: 500;
              color: #1a289d;
            }
          }
          .streamConRight {
            width: 573px;
            padding: 30px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            li {
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 0;
              }
              display: flex;
              align-items: flex-start;
              color: white;
              .listIcon {
                flex: 0 0 25px;
                height: 21px;
                background: (url("../../assets/icon/goldGou.png"));
                margin-top: 8px;
                margin-right: 20px;
              }
              span {
                font-size: 18px;
                font-weight: 600;
                line-height: 30.5px;
              }
            }
          }
        }
      }
      .useTheAdvantage {
        background-color: #fbfbfb;
        padding: 100px 0;
        .helpMakeMoney {
          width: 1200px;
          margin: 50px auto;
          display: flex;
          justify-content: center;
          .helpMakeMoneyLeft {
            flex: 0 0 510px;
            padding: 10px;
            h3 {
              font-size: 41px;
              font-weight: 700;
              line-height: 50px;
              margin-bottom: 20px;
              color: #404040;
            }
            button {
            }
          }
          .helpMakeMoneyRight {
            padding: 10px;
            width: 607px;
            p {
              color: #404040;
              margin-bottom: 14px;
              font-size: 18px;
              font-weight: 400;
              line-height: 33px;
            }
          }
        }
        .logisticalAdvantages {
          width: 1200px;
          margin: 0 auto 20px;
          display: flex;
          .paddingBox {
            padding: 10px;
            .logisticalAdvantagesOpt {
              width: 379px;
              padding: 45px 25px 45px 25px;
              background-color: #ffffff;
              border-style: solid;
              border-width: 1px 1px 1px 1px;
              border-color: #e8e8e8;
              border-radius: 6px 6px 50px 6px;
              box-shadow: 0px 36px 19px -15px rgba(0, 0, 0, 0.05);
              img {
                margin-bottom: 11px;
                $size: 46px;
                width: $size;
                height: $size;
              }
              .boxTitle {
                margin-bottom: 8px;
                color: #404040;
                font-size: 22px;
                font-weight: 600;
                line-height: 1.5;
              }
              .boxCon {
                color: #404040;
                font-size: 18px;
                font-weight: 400;
                line-height: 33px;
              }
            }
          }
        }
      }
    }
  }
}
.imgRow {
  // background-color: #2a365c;
  background-image: url("../../assets/newImg/d20a9ee3029785fea4cb14b39834e62\(1\).jpg");
  background-position: center;
  background-size: 100% auto;
  .imgBox {
    margin: 0 auto;
    height: 662px;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgRowconBox {
      width: 1200px;
      margin: 0 auto;
      padding: 0 575px 0 40px;
      .h1title {
        font-size: 31px;
        font-weight: 600;
        line-height: 1.4em;
        color: #ffffff;
        margin-bottom: 20px;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 33px;
        margin-bottom: 14px;
        color: #ffffff;
      }
    }
  }
}
</style>
