<template>
  <div class="coupon">
    <nav>
      <span :class="type == 1 ? 'active' : ''" @click="type = 1">{{
        $fanyi("我的券包")
      }}</span
      ><span :class="type == 2 ? 'active' : ''" @click="type = 2">{{
        $fanyi("使用记录")
      }}</span>
    </nav>

    <div class="main">
      <!-- 优惠券列表 -->
      <div class="couponList" v-if="type == 1">
        <div v-for="(item, index) in couponList" :key="index" class="item">
          <div class="title">
            <span v-if="item.type != 30">{{ $fanyi("优惠券") }}</span>
            <span v-else> {{ $fanyi("代金券") }}</span>
            <div class="border"></div>
          </div>
          <div class="kin" v-if="item.type == 30">{{ item.free_kin }}€</div>
          <div class="kin name" v-else>{{ item.title }}</div>
          <div class="limited">
            {{ $fanyi("订单金额") }} >
            {{ item.restrictions }}
          </div>
          <div class="num">Nº.{{ item.number }}</div>
          <div class="efficacious">
            <span v-if="sameDate(item.free_datetime_end)" class="expire">
              {{ $fanyi("今日到期") }}
            </span>
            <span v-else>{{ $fanyi("有效期") }}</span>
            <p>{{ item.free_datetime_start }}</p>
            <p>{{ item.free_datetime_end }}</p>
          </div>
        </div>
      </div>
      <!-- 使用列表 -->
      <div class="couponRecord" v-else>
        <div class="search">
          <el-select v-model="datas.type" :placeholder="$fanyi('请选择')">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="input">
            <el-input
              v-model="datas.keywords"
              :placeholder="$fanyi('请输入内容')"
            ></el-input>
          </div>
          <el-button type="primary" @click="couponRecord">{{
            $fanyi("搜索")
          }}</el-button>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            style="width: 100%"
            border
            :header-cell-style="{ background: '#F0F0F0' }"
          >
            <el-table-column
              prop="rowNumber"
              label="Nº"
              align="center"
              width="50"
            >
            </el-table-column>
            <el-table-column
              prop="number"
              :label="$fanyi('券编码')"
              align="center"
              width="170"
            >
            </el-table-column>
            <el-table-column
              prop="title"
              :label="$fanyi('券类别')"
              align="center"
              width="150"
            >
              <template slot-scope="scope">
                <div>
                  {{ $fanyi(scope.row.title) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="free_kin"
              :label="$fanyi('额度/可使用次数')"
              align="center"
              width="180px"
            >
            </el-table-column>
            <el-table-column
              prop="address"
              :label="$fanyi('有效日期')"
              align="center"
              width="230px"
            >
              <template slot-scope="scope">
                <p>{{ scope.row.free_datetime_start }}</p>

                <p>{{ scope.row.free_datetime_end }}</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="updatetime"
              :label="$fanyi('使用日期')"
              align="center"
              width="170px"
            >
            </el-table-column>
            <el-table-column
              prop="address"
              :label="$fanyi('使用状态')"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status == 10">
                  {{ $fanyi("待使用") }}
                </span>
                <span v-else-if="scope.row.status == 20">
                  {{ $fanyi("已使用") }}
                </span>
                <span v-else-if="scope.row.status == 30">
                  {{ $fanyi("已过期") }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="order_sn"
              :label="$fanyi('订单号')"
              align="center"
              width="200"
            >
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="allpag">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="datas.page"
            :page-size="10"
            :page-sizes="[10, 20, 30, 50]"
            layout=" prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sameDate } from "../../../../utlis/date";
export default {
  data() {
    return {
      type: 1,
      couponList: [],
      datas: {
        page: 1,
        pageSize: 10,
        keywords: "",
        type: "",
      },
      options: [
        {
          label: this.$fanyi("已使用"),
          value: "已使用",
        },
        {
          label: this.$fanyi("已过期"),
          value: "已过期",
        },
      ],
      value: "",
      tableData: [],
      total: 0,
    };
  },
  created() {
    this.couponRecord();
    this.couponGet();
  },
  methods: {
    couponGet() {
      this.$api.couponGet({ page: 1, pageSize: 999 }).then((res) => {
        this.couponList = res.data.data;
        //console.log(this.couponList);
      });
    },
    couponRecord() {
      this.$api.couponRecord(this.datas).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    handleCurrentChange(val) {
      this.datas.page = val;
      this.couponRecord();
    },
    sameDate(time) {
      //console.log(sameDate(time));
      return sameDate(time);
    },
  },
};
</script>
<style lang="scss" scoped>
.coupon {
  background-color: #f8f7f7;
  .main {
    padding: 40px;
    background-color: #fff;
    min-height: 300px;
    .couponList {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 210px;
        height: 286px;
        margin: 0 0px 20px 0;
        background: url("../../../../assets/user-img/index/coupon.svg");
        background-size: 100%;
        padding: 20px;
        .title {
          color: #fff;
          font-size: 18px;
        }
        .border {
          width: 20px;
          height: 2px;
          background: #ffffff;
          margin-top: 9px;
        }
        .kin {
          margin-top: 20px;
          font-size: 36px;
          font-weight: 700;
          color: #fff;
          text-align: center;
          height: 45px;
          overflow: hidden;
        }
        .name {
          font-size: 18px;
        }
        .limited {
          text-align: center;
          margin-top: 20px;
          color: #ffffff;
          font-size: 12px;
        }
        .num {
          margin-top: 25px;
          text-align: center;
          font-size: 12px;
          color: #fff;
          transform: scale(0.9);
        }
        .efficacious {
          font-size: 12px;
          margin-top: 15px;
          text-align: center;
          span {
            line-height: 20px;
          }
          .expire {
            color: #f25151;
          }
        }
      }
    }
  }
}
.couponRecord {
  .search {
    /deep/.el-input__inner {
      border-radius: 4px;
      border-color: #c0c4cc;
    }
    /deep/.el-input__icon {
      color: #c0c4cc;
      font-weight: 1000;
    }
    .input {
      display: inline-block;
      width: 390px;
      margin-left: 20px;
    }
    .el-button {
      border-radius: 4px;
      transform: translate(-70px, 0);
    }
  }
  .table {
    margin-top: 20px;
  }
  .allpag {
    margin-top: 30px;
    text-align: center;
  }
}
nav {
  display: flex;
  span {
    display: block;
    width: 130px;
    height: 50px;
    background: #e8e8e8;
    border: 1px solid #e1e1e1;
    border-bottom: none;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    &.active {
      background: #ffffff;
    }
  }
}
</style>
