<template>
  <div id="app">
    <div class="zoomRoom" :style="'zoom:' + roomNum">
      <messae />
      <div class="yuan" v-if="benDi" @click="qieHuan()"></div>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
      <!-- 登录弹窗 -->
      <loginDia />
      <!-- 注册弹窗 -->
      <registerDia />
      <!-- 侧边栏 -->
      <sidebar />
    </div>
  </div>
</template>

<script>
import messae from "@/components/message/index.vue";
import loginDia from "./components/public/loginDia.vue";
import registerDia from "@/components/public/registerDia.vue";
import store from "./store/index";
import sidebar from "@/components/public/sidebar.vue";
document.write(
  unescape(
    "%3Cspan id='cnzz_stat_icon_1281176246'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/stat.php%3Fid%3D1281176246%26show%3Dpic' type='text/javascript'%3E%3C/script%3E"
  )
);
export default {
  data() {
    return {
      roomNum: 1,
      time: false,
    };
  },
  computed: {
    benDi() {
      return [
        "http://localhost:8080",
        "http://localhost:8081",
        "http://localhost:8082",
        "http://localhost:8083",
        "http://192.168.2.14:8080",
        "http://192.168.2.14:8081",
        "https://eu.rakumart.cn/",
      ].includes(location.origin);
    },
  },

  components: {
    loginDia,
    registerDia,
    messae,
    sidebar,
  },
  mounted() {},
  created() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);

    if (sessionStorage.getItem("backendPersonnel")) {
      ////backendPersonnel用sessionStorage让每个页面单独判断是否是操作客户页面

      document.addEventListener("click", () => {
        // console.log(
        //   localStorage.getItem("backgroundOperationID"),
        //   sessionStorage.getItem("user_id") //当前的用户登录id必须存在sessionStorage里面,因为在请求用户信息接口并赋值的时候localStorage会共通数据,导致不能判断是否已经变更了账号,sessionStorage因为统一域名也不共通的特性可以胜任这个功能
        // );
        if (
          sessionStorage.getItem("user_id") &&
          localStorage.getItem("backgroundOperationID") !=
            sessionStorage.getItem("user_id")
        ) {
          this.$fun.exitOperation();
        }
      });
    }

    document.getElementsByName(
      "description"
    )[0].content = this.$store.state.TagDescription;
    if (this.$store.state.goodsList.length == 1) {
      this.$api
        .getShopGroup(this.form)
        .then((result) => {
          this.lists = result.data;
          this.$store.commit("getgoodsList", result.data);
          this.allListShow();
        })
        .catch((err) => {});
    }
    // 预加载登录页面图片
    let image = new Image();

    image.src = require("./assets/login/bg.jpg");

    this.commonExchangeRate();
    if (localStorage.getItem("user_token")) {
      this.$api.EuropegetUserInfo().then((res) => {
        // 登录失效
        if (res.code != 0) {
          this.$store.commit("userData", null);

          localStorage.removeItem("backgroundOperationID");
          sessionStorage.removeItem("backendPersonnel");
          localStorage.removeItem("user_token");
          sessionStorage.removeItem("user_id");

          // 有需要验证的用户中心
          let requireAuth = this.$route.matched[0].meta.requireAuth;
          if (requireAuth) {
            this.$fun.routerToPage("/");
          }
          return;
        } else {
          return this.$store.commit("userData", res.data); // 用户信息存储
        }
      });
    } else {
      this.$store.commit("userData", null);

      localStorage.removeItem("backgroundOperationID");
      sessionStorage.removeItem("backendPersonnel");
      localStorage.removeItem("user_token");
      sessionStorage.removeItem("user_id");
    }

    this.$store.dispatch("getTimMessageUnreadNum");
    // 获取未读消息数量
    document.addEventListener("click", () => {
      if (!this.time) {
        //这是为了确保在页面上的点击事件后面执行
        setTimeout(() => {
          this.$store.dispatch("getTimMessageUnreadNum");
          this.time = true;
          setTimeout(() => {
            this.time = false;
          }, 10000);
        }, 100);
      }
    });
    document.addEventListener("scroll", () => {
      if (!this.time) {
        this.$store.dispatch("getTimMessageUnreadNum");
        this.time = true;
        setTimeout(() => {
          this.time = false;
        }, 10000);
      }
    });
  },
  methods: {
    // // 获取未读消息数量
    // getTimMessageUnreadNum() {
    //   let user_token = localStorage.getItem("user_token");

    //   if (user_token) {
    //     let userInfo = JSON.parse(sessionStorage.getItem("vuex")).userInfo;
    //     Vue.prototype.$api
    //       .messageUnread({ uid: userInfo.user_id })
    //       .then((res) => {
    //         //接下来的操作
    //         if (res.data.code) {
    //           commit("getunread", res.data.count);
    //         } else {
    //           commit("getunread", 0);
    //         }
    //       });
    //   }
    // },
    checkScreenSize() {
      let width = window.innerWidth;
      let height = window.innerHeight;

      let widthbai = Math.min(width / 2133, 1);
      let heightbai = Math.min(height / 2133, 1);

      this.roomNum = Math.max(Math.max(widthbai, heightbai), 0.625); // 0.625是限制最小缩到宽度1200px， 1200/1920=0.625
      // console.log(widthbai, heightbai, 1080 * this.roomNum);
    },
    qieHuan() {
      store.commit("changeData", !store.state.fanyi);
    },
    fanyi() {
      localStorage.removeItem("fanyi");
      this.$forceUpdate();
    },
    commonExchangeRate() {
      // 获取汇率
      this.$api.commonExchangeRate().then((res) => {
        this.$store.commit("getexchangeRate", res.data);
      });
    },
  },
};
</script>
<style lang="scss">
@import "./css/mixin";
// // 外部聊天隐藏样式
// .zsiq_flt_rel {
//   display: none !important;
// }
.yuan {
  position: fixed;
  top: 50%;
  right: 10px;
  border: solid;
  width: 10px;
  height: 10px;
  z-index: 999;
  cursor: pointer;
}

html,
body,
#app {
  min-width: 1200px;
  // zoom: 0.8;
  background-color: #f6f6f6;
  height: 100%;
  min-height: 100%;
  font-family: "Open Sans", Sans-serif;
}

.zoomRoom {
  // min-height: 1000px;
  height: 100%;
  // min-height: 100%;
}

// @media (min-width: 2100px) {
//   html {
//     height: unset;
//   }
//   .zoomRoom {
//     zoom: 1.2;
//   }
// }

// @media (max-width: 1728px) {
//   #app {
//     zoom: 0.9;
//   }
// }
// @media (max-width: 1536px) {
//   #app {
//     zoom: 0.8;
//   }
// }

// @media (max-width: 1440px) {
//   #app {
//     zoom: 0.75;
//   }
// }

// @media (max-width: 1300px) {
//   #app {
//     zoom: 0.67;
//   }
// }

/deep/ .el-input__inner {
  font-family: "Open Sans", Sans-serif;
  color: pink !important;
}
* {
  box-sizing: border-box;
  word-break: normal;
}
a {
  cursor: pointer;
}
.poinDone {
  pointer-events: none;
  &*,
  *,
  *::before {
    pointer-events: none;
  }
}
input[type="checkbox"] {
  $checkBoxSize: 16px;
  -webkit-appearance: none;
  width: $checkBoxSize;
  height: $checkBoxSize;
  border-radius: 4px;
  border: 1px solid #888888;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:checked {
    background: $homePageSubjectColor;
    border: none;
  }
  &:checked::after {
    content: "";

    position: absolute;
    background: transparent;
    border: #fff solid 1.5px;
    border-top: none;
    border-right: none;
    height: 2px;
    width: 40%;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

button {
  cursor: pointer;
  transition: 0.3s;
  background-color: transparent;
}
.anniu {
  border: solid;
  position: fixed;
  width: 100px;
  height: 100px;
  left: 0px;
  z-index: 55999999;
}
// 去除弹出框背景颜色等
.el-popover.el-popper.notHaveBackground {
  background-color: transparent;
  box-shadow: none;
  border: none;
  margin-top: 0;
  padding: 0;
  .popper__arrow {
    display: none;
  }
}
// 禁用状态下的组件字体颜色
.is-disabled .el-input__inner {
  color: #666666;
}
</style>
<style>
#cnzz_stat_icon_1281134212 {
  opacity: 0;
  height: 0;
}
</style>
