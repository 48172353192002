<template>
  <div v-html="content"></div>
</template>
<script>
// 富文本插件的样式,后台使用富文本插件写的通知需要加载以下几个样式才能正常使用
export default {
  props: {
    content: {
      default: "",
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/css/mixin.scss";
</style>
<style scoped src="../../../../css/quillcore.css"></style>
<style scoped src="../../../../css/quillbubble.css"></style>
<style scoped src="../../../../css/quillsnow.css"></style>
