<template>
  <div>
    <oDialog
      ref="WhatsAppdialog"
      class="whatsAppdialog"
      :config="{ showBtn: false }"
    >
      <div class="whatAPPbox dip fdcolmun">
        <div class="headerBox dip fdcolmun">
          <div class="headerimg ">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/67738fe4cddba.svg"
              alt=""
            />
          </div>
          <h2>WhatsApp</h2>
          <p>{{ $fanyi("扫描手机上的QR码，开始聊天") }}</p>
        </div>
        <img
          class="qrcode"
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/67738b2d6424a.png"
          alt=""
        />

        <el-button
          class="bgBlueBtn"
          @click="
            $refs.WhatsAppdialog.cancel();
            $fun.chat(2);
          "
          >{{ $fanyi("发起聊天") }}</el-button
        >
      </div>
    </oDialog>
    <!-- 展开按钮 -->
    <div class="slipo closeSlipo">
      <transition name="right">
        <VueDragResize
          ref="VueDragResize1"
          :isActive="false"
          :isResizable="false"
          :parentLimitation="false"
          :w="66"
          :h="66"
          :x="(xyData.VueDragResize1 || {}).x || 0"
          :y="(xyData.VueDragResize1 || {}).y || 0"
          axis="y"
          @clicked="clicked($event, 'VueDragResize1')"
          @dragging="dragging($event, 'VueDragResize1')"
          @dragstop="dragstop($event, 'VueDragResize1')"
        >
          <div class="sliBox dragBox" v-if="!isOpen" @click="openMenu">
            <div class="sliItem">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba1585b913.svg"
                alt=""
              />
            </div>
          </div>
        </VueDragResize>
      </transition>
    </div>
    <div class="slipo">
      <transition name="right">
        <div class="sliBox" v-if="isOpen">
          <div class="clickBox">
            <div class="clickItem" @click="$fun.routerToPage('/user/index')">
              <div class="imgBox">
                <img
                  class="img1"
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba0533c5e0.svg"
                  alt=""
                />
                <img
                  class="img2"
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba05339ce8.svg"
                  alt=""
                />
              </div>
              <span>{{ $fanyi("面板控制") }}</span>
            </div>

            <div
              class="clickItem"
              @click="$fun.routerToPage('/guia-de-usuario')"
            >
              <div class="imgBox">
                <img
                  class="img1"
                  src=" https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba0554535b.svg
"
                  alt=""
                />
                <img
                  class="img2"
                  src=" https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba053abdad.svg

"
                  alt=""
                />
              </div>
              <span>{{ $fanyi("快速用户指南") }}</span>
            </div>

            <div class="clickItem" @click="$fun.routerToPage('/logistica')">
              <div class="imgBox">
                <img
                  class="img1"
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba053b690b.svg"
                  alt=""
                />
                <img
                  class="img2"
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba053ae2f3.svg"
                  alt=""
                />
              </div>
              <span>{{ $fanyi("物流价格") }}</span>
            </div>

            <div
              class="clickItem"
              @click="$fun.routerToPage('/servicios-adicionales')"
            >
              <div class="imgBox">
                <img
                  class="img1"
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba054e9749.svg"
                  alt=""
                />
                <img
                  class="img2"
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba054d3c54.svg"
                  alt=""
                />
              </div>
              <span>{{ $fanyi("option说明界面") }}</span>
            </div>
            <!-- $fun.chat(2) -->
            <div class="clickItem" @click="$refs.WhatsAppdialog.open()">
              <div class="imgBox">
                <img
                  class="img1"
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba05565309.svg"
                  alt=""
                />
                <img
                  class="img2"
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba054eb2e7.svg"
                  alt=""
                />
              </div>
              <span>WhatsApp</span>
            </div>

            <div class="clickItem" @click="$fun.chat(1)">
              <div class="imgBox">
                <div class="unreadBox dip" v-if="$store.state.unread">
                  {{ $store.state.unread }}
                </div>
                <img
                  class="img1"
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba05678241.svg"
                  alt=""
                />
                <img
                  class="img2"
                  src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba0556ce0f.svg"
                  alt=""
                />
              </div>
              <span>RAKUCHAT</span>
            </div>
          </div>
          <div
            class="sliItem smallHeight"
            @click="
              canClick = true;
              openMenu();
            "
          >
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/676ba1585b913.svg"
              alt=""
              style="rotate: 180deg;"
            />
          </div>
        </div>
      </transition>
    </div>
    <!-- 到最上方和最下方按钮 -->
    <div class="slipo topAndBotton">
      <VueDragResize
        :isActive="false"
        :isResizable="false"
        :parentLimitation="false"
        :w="55"
        :h="100"
        :x="(xyData.VueDragResize2 || {}).x || 0"
        :y="(xyData.VueDragResize2 || {}).y || 0"
        axis="y"
        ref="VueDragResize2"
        @clicked="clicked($event, 'VueDragResize2')"
        @dragging="dragging($event, 'VueDragResize2')"
        @dragstop="dragstop($event, 'VueDragResize2')"
      >
        <div class="sliBox dragBox">
          <div class="sliItem" @click="toTop">
            <div class="tabclickBox">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6683797671ecb.svg"
                alt=""
              />
            </div>
          </div>
          <div class="sliItem" @click="toBotton">
            <div class="tabclickBox">
              <img
                style="rotate:180deg"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6683797671ecb.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </VueDragResize>
    </div>
  </div>
</template>
<script>
import VueDragResize from "vue-drag-resize/src";
import oDialog from "@/components/public/Dialog.vue";
export default {
  data() {
    return {
      isOpen: true,
      elePosiStart: null, //点击可拖拽元素时的开始位置(用于判断是否执行点击事件)
      canClick: false, //控制是否执行点击事件,为false就不执行
    };
  },
  components: {
    oDialog,
    VueDragResize, //isResizable 是否开启缩放功能 详细网址:https://blog.csdn.net/qq_17627195/article/details/120720248 || https://www.jianshu.com/p/36efabc47618
  },
  computed: {
    // 存在sessionStorage的xy初始数据
    // 格式为{
    //     "VueDragResize1": { //ref名
    //         "x": 0,       x初始值
    //         "y": -20      y初始值
    //     },
    //     "VueDragResize2": {
    //         "x": 0,
    //         "y": -113
    //     }
    // }
    // 获取位置信息: xyData[ref名字][x或y]
    xyData() {
      return JSON.parse(sessionStorage.getItem("xyData"));
    },
  },
  created() {
    // 如果sessionStorage没有xyData就新建一个
    if (!sessionStorage.getItem("xyData")) {
      sessionStorage.setItem("xyData", JSON.stringify({}));
    }
  },
  mounted() {},
  methods: {
    // 滑动到顶部
    toTop() {
      if (!this.canClick) return;

      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: 0,
      });
    },
    // 滑动到底部
    toBotton() {
      if (!this.canClick) return;

      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: 11111111111111111111111111,
      });
    },
    // 展开菜单
    openMenu() {
      if (!this.canClick) return;

      // console.log("打开菜单");
      this.isOpen = !this.isOpen;
    },

    // 拖拽组件点击事件
    clicked(e, refName) {
      // console.log("点击", e);
      // 保存一个点击时的位置信息
      this.elePosiStart =
        this.$refs[refName].$el.style.left +
        "," +
        this.$refs[refName].$el.style.top;

      // 初始化可以执行点击事件
      this.canClick = true;
    },

    // 拖拽组件拖拽中事件
    dragging({ left, top, width, height }, refName) {
      // console.log("拖拽中", left, top, width, height);
    },
    // 组件拖拽结束事件
    dragstop({ left, top, width, height }, refName) {
      // console.log("拖拽结束", left, top, width, height);
      this.$refs[refName].$el.style.left = 0; //控制元素始终靠在右边

      let elePosiEnd =
        this.$refs[refName].$el.style.left +
        "," +
        this.$refs[refName].$el.style.top;

      // 如果最终位置和保存的点击时的位置不一样就不执行点击事件并且保存位置信息到sessionStorage
      if (elePosiEnd != this.elePosiStart) {
        this.canClick = false;

        this.setxyData(
          refName,
          this.$refs[refName].$el.style.left,
          this.$refs[refName].$el.style.top
        );
      }
    },

    // 保存位置数据到sessionStorage,格式为{
    //     "VueDragResize1": { //ref名
    //         "x": 0,       x初始值
    //         "y": -20      y初始值
    //     },
    //     "VueDragResize2": {
    //         "x": 0,
    //         "y": -113
    //     }
    // }
    setxyData(refName, x, y) {
      let xyDataLocal = JSON.parse(sessionStorage.getItem("xyData"));
      if (!xyDataLocal[refName]) xyDataLocal[refName] = {};
      xyDataLocal[refName].x = Number(x.replace("px", ""));
      xyDataLocal[refName].y = Number(y.replace("px", ""));
      sessionStorage.setItem("xyData", JSON.stringify(xyDataLocal));
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.dragBox {
  //VueDragResize组件里的元素position: [fixed/relative]需要手动设置
  position: fixed;
  cursor: pointer;
}
.slipo {
  position: fixed;
  top: 45%;
  right: 0;
  transform: translate(0%, -50%);
  z-index: 5;
}
.closeSlipo {
  // 子元素设置了定位之后需要手动设置width,否则width默认为0
  width: 66px;
  top: 45%;
}

.sliBox {
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px 0px 0px 12px;

  .sliItem {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    @extend .dip;
    cursor: pointer;
    img {
      fill: rgb(230, 39, 141);
    }
  }

  .clickBox {
    margin: 20px 10px 0;
    gap: 10px;
    width: 90px;
    @extend .fdcolmun;
    .clickItem {
      @extend .dip;
      flex-direction: column;
      cursor: pointer;
      justify-content: flex-start;
      //   height: 90px;
      transition: 0.3s;

      * {
        transition: 0.3s;
      }
      .imgBox {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @extend .dip;
        margin-bottom: 5px;
        position: relative;
        .unreadBox {
          position: absolute;
          top: 0;
          right: 0;
          min-width: 16px;
          height: 16px;
          background: #ff5c00;
          border-radius: 8px;
          font-size: 12px;
          line-height: 1;
          color: #ffffff;
          padding: 0 4px;
        }
      }
      .img1 {
        display: block;
      }
      .img2 {
        display: none;
      }
      span {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        padding: 0 10px;
        line-height: 1.2;
      }
    }

    .clickItem:hover {
      .img1 {
        display: none;
      }
      .img2 {
        display: block;
      }
      .imgBox {
        background-color: #eff5fd;
      }
      span {
        color: #3871e0;
      }
    }
  }
}

.topAndBotton {
  // 子元素设置了定位之后需要手动设置width,否则width默认为0
  width: 52.41px;
  top: 90%;
  .sliItem {
    width: unset;
    height: unset;
  }
  .tabclickBox {
    @extend .dip;
    width: 50px;
    height: 50px;
  }
}

// 从右进入
.right-enter {
  transform: translateX(100vw);
  opacity: 0;
}
.right-leave-active {
  transform: translateX(64px);
  opacity: 0;
}

.right-leave-active,
.right-enter-active {
  transition: all 0.3s;
}

// 隐藏VueDragResize组件拖拽时的边框
/deep/.vdr.active:before {
  outline: none;
}
</style>
<style lang="scss">
.whatsAppdialog {
  .el-dialog {
    border-radius: 8px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      background-color: transparent;
      padding: 0;

      .whatAPPbox {
        gap: 30px;
        padding: 50px 20px;
        .headerBox {
          .headerimg {
            width: 80px;
            height: 80px;
            background: #f5f5f5;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            img {
              width: 40px;
            }
          }
          h2 {
            font-weight: 700;
            font-size: 24px;
            color: #121212;
            line-height: 20px;
            margin-bottom: 12px;
          }
          p {
            font-weight: 400;
            font-size: 14px;
            color: #121212;
            line-height: 16px;
            padding: 0 100px;
            text-align: center;
          }
        }

        .qrcode {
          width: 220px;
        }

        .bgBlueBtn {
          width: 100%;
          height: 56px;
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
