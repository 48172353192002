// 调查问卷ops

import Vue from "vue";
import fanyi from "@/utlis/language.js";
import fun from "@/utlis/CommonlyUsedFunctions.js";
// import Vuex from 'vuex'
// Vue.use(Vuex)

let ps = {
  // --------------------------------------------data----------------------
  step: 1, //大的分页
  formregister: { market_channel: [], country_phone: "+34" },
  // --------------------------------------------ref-----------------------
  CompletedFilling: null,
  RakumartExperience: null,
  //---------------------------------------------methods-------------------
  backLastPage() {
    if (ps.step == 1) {
      ps.RakumartExperience.goBack();
    } else if (ps.step == 2) {
      ps.CompletedFilling.goBack();
    }
  },
  goNext() {
    ps.step++;
  },
};

export default ps;
