<template>
  <DialogVue ref="dialog" :config="config">
    <div class="tishiMain">
      <h1>{{ $fanyi("如何运作") }}</h1>
      <div class="payStepCon">
        <div class="payStep">
          <div class="stepIcon">
            <div class="yen"><div></div></div>
            <div class="verticalBar"></div>
          </div>
          <div class="stepCon">
            <h2>{{ $fanyi("银行转帐") }}</h2>
            <p>{{ $fanyi("把你的订单转到我们的银行账户。") }}</p>
          </div>
        </div>
        <div class="payStep">
          <div class="stepIcon">
            <div class="yen"><div></div></div>
            <div class="verticalBar" v-if="!ispeisong"></div>
          </div>
          <div class="stepCon">
            <h2>{{ $fanyi("订单正在处理中") }}</h2>

            <p>{{ $fanyi("请允许1至2个工作日处理订单。") }}</p>
          </div>
        </div>
        <div class="payStep" v-if="!ispeisong">
          <div class="stepIcon">
            <div class="yen"><div></div></div>
          </div>
          <div class="stepCon">
            <h2>{{ $fanyi("我们进行购买") }}</h2>
            <p>{{ $fanyi("我们购买您的产品并将其送到我们的仓库。") }}</p>
          </div>
        </div>
      </div>
      <div class="bottomBtn">
        <el-button type="primary" @click="closeDia">{{
          $fanyi("已理解")
        }}</el-button>
      </div>
    </div>
  </DialogVue>
</template>
<script>
import DialogVue from "../../../components/public/Dialog.vue";
export default {
  props: {
    ispeisong: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      config: {
        top: "20vh",
        width: "464px",
        title: "",
        btnTxt: ["删除", "取消"],
      },
    };
  },
  components: {
    DialogVue,
  },
  computed: {},
  mounted() {},
  methods: {
    open() {
      this.$refs.dialog.open(
        "",
        () => {},
        () => {}
      );
    },
    closeDia() {
      this.$refs.dialog.cancel();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../css/mixin.scss";
/deep/.el-dialog__header {
  display: none;
}
/deep/.el-dialog__footer {
  display: none;
}

.tishiMain {
  h1 {
    height: 22px;
    font-size: 22px;
    font-weight: 400;
    color: #2f2f2f;
    line-height: 22px;
    margin-bottom: 40px;
  }
  .payStepCon {
    .payStep {
      display: flex;
      align-items: flex-start;
      height: 97px;
      display: flex;
      align-items: flex-start;

      .stepIcon {
        width: 14px;
        margin: 0 20px 0 0;
        .yen {
          width: 14px;
          height: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #bfbfbf;
          border-radius: 50%;
          div {
            content: "";
            width: 8px;
            height: 8px;
            background: #bfbfbf;
            border: 1px solid #bfbfbf;
            border-radius: 50%;
          }
        }
        .verticalBar {
          height: 79px;
          width: 1px;
          margin: 0 auto;
          background-color: #e8e8e8;
        }
      }
      .stepCon {
        h2 {
          font-size: 19.08px;
        }
        p {
          width: 100%;
          font-size: 16px;
          color: #6a6a6a;
          word-wrap: break-word;
          word-break: normal;
        }
      }
    }
  }
  .bottomBtn {
    margin: 30px 0;
    text-align: center;
    .el-button {
      width: 220px;
      height: 58px;
      background: #1c2899;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
