<template>
  <div style="background: white">
    <Head></Head>
    <div class="page">
      <h1>Aviso legal</h1>
      <h2>1. DATOS IDENTIFICATIVOS</h2>
      <p style="margin-bottom: 50px">
        En cumplimiento con el deber de información recogido en artículo 10 de
        la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
        Información y del Comercio Electrónico, a continuación, el titular del
        dominio web es RAKUMART INVESTMENT CO., LIMITED (en adelante,
        RAKUMART.ES), correo electrónico soporte@rakumart.es.
      </p>
      <h2>2. USUARIOS</h2>
      <p style="margin-bottom: 50px">
        El acceso y/o uso de este portal de EL TITULAR atribuye la condición de
        USUARIO, que acepta, desde dicho acceso y/o uso, las Condiciones
        Generales de Uso aquí reflejadas. Las citadas Condiciones serán de
        aplicación independientemente de las Condiciones Generales de
        Contratación que en su caso resulten de obligado cumplimiento.
      </p>
      <h2>3. USO DEL PORTAL</h2>
      <p>
        La web <a href="/">www.rakumart.es</a> proporciona el acceso a multitud
        de informaciones, servicios, programas o datos (en adelante, “los
        contenidos”) en Internet pertenecientes Al TITULAR. El USUARIO asume la
        responsabilidad del uso del portal.
      </p>
      <p>
        El USUARIO será responsable de aportar información veraz y lícita cuando
        se le solicite y se compromete a hacer un uso diligente de la
        información y contenidos que conozca y que se ofrecen a través del
        portal y con carácter enunciativo, pero no limitativo, a no emplearlos
        para:
      </p>
      <ul>
        <li>
          incurrir en actividades ilícitas, ilegales o contrarias a la buena fe
          y al orden público;
        </li>
        <li>
          difundir contenidos o propaganda de carácter racista, xenófobo,
          pornográfico-ilegal, de apología del terrorismo o atentatorio contra
          los derechos humanos;
        </li>
        <li>
          provocar daños en los sistemas físicos y lógicos del TITULAR, de sus
          proveedores o de terceras personas, introducir o difundir en la red
          virus informáticos o cualesquiera otros sistemas físicos o lógicos que
          sean susceptibles de provocar los daños anteriormente mencionados;
        </li>
        <li>
          intentar acceder y, en su caso, utilizar las cuentas de correo
          electrónico de otros usuarios y modificar o manipular sus mensajes. El
          TITULAR se reserva el derecho de retirar todos aquellos comentarios y
          aportaciones que vulneren el respeto a la dignidad de la persona, que
          sean discriminatorios, xenófobos, racistas, pornográficos, que atenten
          contra la juventud o la infancia, el orden o la seguridad pública o
          que, a su juicio, no resultaran adecuados para su publicación. En
          cualquier caso, el TITULAR no será responsable de las opiniones
          vertidas por los usuarios a través de los foros, chats, u otras
          herramientas de participación.
        </li>
      </ul>
      <h2>4. PROTECCIÓN DE DATOS</h2>
      <p>
        El TITULAR cumple con las directrices de la normativa española en
        materia de Protección de Datos de Carácter Personal, y vela por
        garantizar un correcto uso y tratamiento de los datos personales del
        usuario. Todos los tratamientos de datos que se realicen en esta web se
        harán cumpliendo la citada normativa proporcionando la información
        necesaria y poniendo a disposición del USUARIO el contacto para el
        ejercicio de los derechos que tiene reconocidos.
      </p>
      <p>
        Más información sobre protección de datos en la Política de Privacidad.
      </p>
      <p style="margin-bottom: 50px">
        Asimismo, el TITULAR informa que da cumplimiento a la Ley 34/2002 de 11
        de julio, de Servicios de la Sociedad de la Información y el Comercio
        Electrónico y le solicitará su consentimiento al tratamiento de su
        correo electrónico con fines comerciales en cada momento.
      </p>
      <h2>PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
      <p style="margin-bottom: 50px">
        El TITULAR por sí o como cesionario, es titular de todos los derechos de
        propiedad intelectual e industrial de su página web, así como de los
        elementos contenidos en la misma (a título enunciativo, imágenes,
        sonido, audio, vídeo, software o textos; marcas o logotipos,
        combinaciones de colores, estructura y diseño, selección de materiales
        usados, programas de ordenador necesarios para su funcionamiento, acceso
        y uso, etc.) o bien de sus licenciantes.
      </p>
      <p>Todos los derechos reservados.</p>
      <p style="margin-bottom: 50px">
        En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de
        la Ley de Propiedad Intelectual, quedan expresamente prohibidas la
        reproducción, la distribución y la comunicación pública, incluida su
        modalidad de puesta a disposición, de la totalidad o parte de los
        contenidos de esta página web, con fines comerciales, en cualquier
        soporte y por cualquier medio técnico, sin la autorización del TITULAR.
        El USUARIO se compromete a respetar los derechos de Propiedad
        Intelectual e Industrial del TITULAR de la página web. Podrá visualizar
        los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos
        en el disco duro de su ordenador o en cualquier otro soporte físico
        siempre y cuando sea, única y exclusivamente, para su uso personal y
        privado. El USUARIO deberá abstenerse de suprimir, alterar, eludir o
        manipular cualquier dispositivo de protección o sistema de seguridad que
        estuviera instalado en <a href="/">www.rakumart.es</a>.
      </p>
      <h2>6. POLÍTICA DE COOKIES</h2>
      <p>
        La empresa informa de su política de cookies, cumpliendo así con lo
        previsto en el artículo 22.2 de la Ley 34/2002, de 11 de julio de
        Servicios de la Sociedad de la Información y de Comercio Electrónico.
      </p>
      <p style="margin-bottom: 50px">
        Más información en nuestra Política de Cookies.
      </p>
      <h2>7. EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</h2>
      <p style="margin-bottom: 50px">
        El TITULAR no se hace responsable, en ningún caso, de los daños y
        perjuicios de cualquier naturaleza que pudieran ocasionar, a título
        enunciativo: errores u omisiones en los contenidos, falta de
        disponibilidad del portal o la transmisión de virus o programas
        maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas
        las medidas tecnológicas necesarias para evitarlo.
      </p>
      <h2>8. MODIFICACIONES</h2>
      <p style="margin-bottom: 50px">
        El TITULAR se reserva el derecho de efectuar sin previo aviso las
        modificaciones que considere oportunas en su portal, pudiendo cambiar,
        suprimir o añadir tanto los contenidos y servicios que se presten a
        través de la misma como la forma en la que éstos aparezcan presentados o
        localizados en su portal.
      </p>
      <h2>9. ENLACES</h2>
      <p>
        En el caso de que en <a href="/">www.rakumart.es</a> se dispusiesen
        enlaces o hipervínculos hacía otros sitios de Internet, El TITULAR no
        ejercerá ningún tipo de control sobre dichos sitios y contenidos. En
        ningún caso, el TITULAR asumirá responsabilidad alguna por los
        contenidos de algún enlace perteneciente a un sitio web ajeno, ni
        garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud,
        amplitud, veracidad, validez y constitucionalidad de cualquier material
        o información contenida en ninguno de dichos hipervínculos u otros
        sitios de Internet. No somos responsables de examinar o evaluar el
        contenido o la exactitud, ni garantizamos ni asumiremos ninguna
        obligación ni responsabilidad por los recursos o páginas web de
        terceros, ni por ningún otro material, producto o servicio de terceros.
      </p>
      <p>
        El TITULAR de <a href="/">www.rakumart.es</a> no es responsable de
        ningún daño o perjuicio relacionado con la compra o el uso de bienes,
        servicios, recursos, contenido o cualquier otra transacción realizada en
        conexión con sitios web de terceros. Revise cuidadosamente las políticas
        y prácticas de terceros, y asegúrese de comprenderlas antes de
        participar en cualquier transacción. Las quejas, reclamos, inquietudes o
        preguntas referentes a productos de terceros deben dirigirse a estos.
      </p>
      <p style="margin-bottom: 50px">
        Igualmente, la inclusión de estas conexiones externas no implicará
        ningún tipo de asociación, fusión o participación con las entidades
        conectadas.
      </p>
      <h2>10. DERECHO DE EXCLUSIÓN</h2>
      <p style="margin-bottom: 50px">
        El TITULAR se reserva el derecho a denegar o retirar el acceso a portal
        y/o los servicios ofrecidos sin necesidad de preaviso, a instancia
        propia o de un tercero, a aquellos usuarios que incumplan las presentes
        Condiciones Generales de Uso.
      </p>
      <h2>11. GENERALIDADES</h2>
      <p style="margin-bottom: 50px">
        El TITULAR perseguirá el incumplimiento de las presentes condiciones,
        así como cualquier utilización indebida de su portal ejerciendo todas
        las acciones civiles y penales que le puedan corresponder en derecho.
      </p>
      <h2>12. MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</h2>
      <p>
        El TITULAR podrá modificar en cualquier momento las condiciones aquí
        determinadas, siendo debidamente publicadas como aquí aparecen.
      </p>
      <p style="margin-bottom: 50px">
        La vigencia de las citadas condiciones irá en función de su exposición y
        estarán vigentes hasta debidamente publicadas. que sean modificadas por
        otras.
      </p>
      <h2>13. LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h2>
      <p>
        La relación entre el TITULAR y el USUARIO se regirá por la normativa
        española vigente y cualquier controversia se someterá a los Juzgados y
        tribunales de la ciudad de Barcelona.
      </p>
    </div>
    <Foot style="width: 100%"></Foot>
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";
export default {
  data() {
    return {};
  },
  components: { Head, Foot },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.page {
  width: 750px;
  margin: 30px auto;
  padding: 10px;
  h1 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
  }
  h2 {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 14.4px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 14.4px;
  }
  ul {
    margin-bottom: 42px;
    margin-top: 18px;
    li {
      margin-left: 40px;
      list-style: decimal;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}
a {
  color: #cc3366;
  &:hover {
    color: black;
  }
}
</style>
