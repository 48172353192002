import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate"; //保留vuex数据
export default new Vuex.Store({
  state: {
    activePage: "",
    userInfo: null, // 登入信息
    userInfoDetail: {}, //登入信息详情
    message: null, //站内信通知
    cartCount: 0, //购物车数量
    searchInfo: "", //搜索关键词
    searchInfoCN: "",
    searchImg: "", //搜索关键图
    exchangeRate: 0, //汇率
    kaishisousuo: false,
    leftSidebarNum: [0, 0, 0, 0, 0], //订单菜单侧边栏数量统计
    pageLoading: null, //是否显示加载中
    goodsList: [{}], //顶部搜索框选项
    goodsListActiveId: "",
    showChinese: false, //是否显示中文
    ordersSumList: {
      temporary: 0,
      delete: 0,
      purchased: 0,
      purchase: 0,
      obligation: 0,
      offer: 0,
      all: 0,
    }, //商品列表各种类商品数据
    sidebarNumList: {
      订单列表: {
        进行中订单: 0,
      },
      问题产品列表: {
        未完成: 0,
      },
      仓库列表: {
        待提出: 0,
        待装箱: 0,
        总数: 0,
      },
      发票: {
        历史发票: 0,
      },
      配送单列表: {
        行中配送单: 0,
      },
      国际物流列表: {
        已发货未签收: 0,
      },
    },
    topScrollMoreHeight: 0, //页面是否滑动到一定高度
    TagTitle: "RAKUMART",
    fanyi: false,
    TagDescription:
      "Ahorra tiempo y costes al importar desde China. Accede al catálogo de miles de proveedores y disfruta de nuestra plataforma de importación todo en 1.",
    showLoginDialog: false, // 是否显示登录弹窗
    showRegisterDialog: false, // 是否显示注册弹窗
    QASearchHistore: [], //QA页面的搜索历史
    unread: 0, //未读数量
  },
  mutations: {
    getunread(state, data) {
      state.unread = data;
    },
    getQASearchHistore(state, data) {
      state.showLoginDialog = data;
    },
    getshowLoginDialog(state, data) {
      state.showLoginDialog = data;
    },
    getshowRegisterDialog(state, data) {
      state.showRegisterDialog = data;
    },
    changeData(state, data) {
      state.fanyi = data;
    },
    changeGoodsName50Str(state, data) {
      state.goodsName50Str = data;
    },
    changeTagDescription(state, data) {
      state.TagDescription = data;
    },
    changeTagTitle(state, data) {
      state.TagTitle = data;
    },
    settopScrollMoreHeight(state, data) {
      state.topScrollMoreHeight = data;
    },
    showChinese(state, data) {
      state.showChinese = data;
    },
    goodsListActiveId(state, data) {
      state.goodsListActiveId = data;
    },
    getgoodsList(state, data) {
      state.goodsList = data;
    },
    pageLoading(state, data) {
      state.pageLoading = data;
    },
    kaishisousuo(state, data) {
      state.kaishisousuo = data;
    },
    getexchangeRate(state, data) {
      state.exchangeRate = data;
    },
    getactivePage(state, data) {
      state.activePage = data;
    },
    // 搜索关键图
    getsearchImg(state, data) {
      state.searchImg = data;
    },
    // 搜索关键词
    getsearchInfo(state, data) {
      state.searchInfo = data;
    },
    getsearchInfoCN(state, data) {
      state.searchInfoCN = data;
    },
    /* 获取登录信息 */
    userData(state, data) {
      state.userInfo = data;
    },
    // 登入信息详情
    getuserInfoDetail(state, data) {
      state.userInfoDetail = data;
    },
    // 站内信通知
    getMessage(state, data) {
      state.message = data;
    },
    /* 退出登录 */
    outUser(state) {
      state.userInfo = null;
      localStorage.removeItem("token");
      state.message = null;
      window.location.reload();
    },
    // 汇率
    rateData(state, data) {
      state.rate = data;
    },
    // 客户会员中心数据
    getUserIndex(state, data) {
      state.userIndex = data;
    },
    // 存储关键词
    setQ(state, data) {
      state.q = data;
    },
    // excelOrder 下单
    getexcelOrder(state, data) {
      state.excelOrder = data;
    },
    // 购物车数量
    setcartCount(state, data) {
      state.cartCount = data;
    },
    // 购物车数量
    sidebarNumList(state, data) {
      state.sidebarNumList = data;
    },
    //获取每个订单数量
    getOrdersSum(state, data) {
      state.ordersSumList = data;
    },
    //获取订单菜单侧边栏数量统计
    getLeftSidebarNum(state, data) {
      state.leftSidebarNum = data;
    },
  },
  modules: {},
  actions: {
    // 获取左侧用户菜单列表
    sidebarNumList({ commit }) {
      let user_token = localStorage.getItem("user_token");
      if (user_token) {
        Vue.prototype.$api.sidebarNum().then((res) => {
          commit("sidebarNumList", res.data);
        });
      }
    },
    //获取每个订单数量
    getOrdersSum({ commit }) {
      let user_token = localStorage.getItem("user_token");
      if (user_token) {
        Vue.prototype.$api.ordersSum().then((res) => {
          commit("getOrdersSum", res.data);
        });
      }
    },
    //获取订单菜单侧边栏数量统计
    getLeftSidebarNum({ commit }) {
      let user_token = localStorage.getItem("user_token");
      if (user_token) {
        Vue.prototype.$api.leftSidebarNum().then((res) => {
          commit("getLeftSidebarNum", res.data);
        });
      }
    },
    // 获取购物车数量
    goodsToCartNum({ commit }) {
      let user_token = localStorage.getItem("user_token");
      if (user_token) {
        Vue.prototype.$api.goodsToCartNum().then((res) => {
          if (res.code == 0) {
            commit("setcartCount", res.data);
          } else {
            commit("setcartCount", 0);
          }
        });
      }
    },
    // 获取用户资料
    EuropegetUserInfo({ commit }) {
      Vue.prototype.$api.EuropegetUserInfo().then((res) => {
        //console.log(res);
        if (res.code == 0) {
          commit("userData", res.data);
        } else {
          commit("userData", null);
        }
      });
    },
    // 获取未读消息数量
    getTimMessageUnreadNum({ commit }) {
      let user_token = localStorage.getItem("user_token");
      if (user_token) {
        let userInfo = JSON.parse(sessionStorage.getItem("vuex")).userInfo;
        Vue.prototype.$api
          .messageUnread({ uid: userInfo.user_id })
          .then((res) => {
            //接下来的操作
            if (res.data.code) {
              commit("getunread", res.data.count);
            } else {
              commit("getunread", 0);
            }
          });
      }
    },
  },

  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
