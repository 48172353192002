var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.$fanyi('选择优惠券或代金券'),"visible":_vm.$parent.CouponsShow,"width":"443px"},on:{"update:visible":function($event){return _vm.$set(_vm.$parent, "CouponsShow", $event)}}},[(_vm.arrType.length != 0)?_c('div',{staticClass:"Con"},[_c('div',{staticClass:"singleCoupon preferential"},_vm._l((_vm.arrType),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$fun.ceil(_vm.$parent.orderData.zongJia) > item.restrictions),expression:"$fun.ceil($parent.orderData.zongJia) > item.restrictions"}],key:index,staticClass:"radial-gradient",class:{ active: _vm.active.arrType == index },on:{"click":function($event){_vm.$fun.ceil(_vm.$parent.orderData.zongJia) < item.restrictions
            ? _vm.$message.error(_vm.$fanyi('未达到使用条件'))
            : _vm.active.arrType == index
            ? (_vm.active.arrType = -1)
            : (_vm.active.arrType = index)}}},[_c('div',{staticClass:"title",class:{
            notUser: _vm.$fun.ceil(_vm.$parent.orderData.zongJia) < item.restrictions,
          }},[_c('span',[_vm._v(" "+_vm._s(_vm.$fanyi("代金券")))])]),_c('div',{staticClass:"optCon",class:{
            notUser: _vm.$fun.ceil(_vm.$parent.orderData.zongJia) < item.restrictions,
          }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$fanyi("编号"))+"："+_vm._s(item.number))]),_c('p',{staticClass:"price"},[_vm._v(_vm._s(item.free_kin)+_vm._s(_vm.$fanyi("欧元")))]),_c('p',{staticClass:"condition"},[_vm._v(" "+_vm._s(_vm.$fanyi("订单金额必须大于"))+" "+_vm._s(item.restrictions)+" ")]),_c('p',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$fanyi("有效期"))+": "+_vm._s(item.free_datetime_start.substr(0, 10))+" / "+_vm._s(item.free_datetime_end.substr(0, 10))+" ")])])])}),0)]):_c('div',{staticClass:"notHaveCoupons"},[_vm._v(_vm._s(_vm.$fanyi("暂无优惠券")))]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"btnGroup"},[_c('button',{on:{"click":function($event){return _vm.setData()}}},[_vm._v(_vm._s(_vm.$fanyi("确认")))]),_c('button',{staticClass:"quXiao",on:{"click":function($event){_vm.$parent.CouponsShow = false}}},[_vm._v(" "+_vm._s(_vm.$fanyi("取消"))+" ")])]),_c('div',{staticClass:"prompt"},[_vm._v(" "+_vm._s(_vm.$fanyi("注意:同一订单只能使用同一类型的优惠券。"))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }