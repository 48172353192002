<template>
  <div class="leftmenuCon">
    <div
      class="itemBox"
      v-for="(item, index) in textData"
      :key="index"
      @click="scrollToTop(item.name)"
      :class="{ active: index == scrollIndex }"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  emits: ["scrollToTop"],
  props: {
    textData: {
      type: Array,
      // required: true,
    },

    // count: {
    //   type: Number,

    //   default: 0,
    // },
  },

  data() {
    return {
      scrollIndex: 0,
    };
  },
  components: {},
  computed: {},
  created() {},
  mounted() {
    // this.throttle(() => {
    window.addEventListener("scroll", this.getscrollIndex);
    // }, 50);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.getscrollIndex);
  },
  methods: {
    scrollToTop(name) {
      this.$emit("scrollToTop", name);
    },

    getscrollIndex() {
      let dd = document.documentElement;
      let topNum = dd.scrollTop;

      for (let i = this.textData.length - 1; i >= 0; i--) {
        if (topNum >= this.textData[i].offsetTop - 50) {
          this.scrollIndex = i;
          break;
        }
      }
    },
    throttle(fn, delay) {
      let timer = null;
      return function() {
        let context = this;
        let args = arguments;
        if (!timer) {
          timer = setTimeout(function() {
            fn.apply(context, args);
            timer = null;
          }, delay);
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.leftmenuCon {
  position: absolute;
  top: 0;
  right: 100%;
  width: max-content;
  padding: 10px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #f0f3f6;
  margin-right: 100px;
  .itemBox {
    height: 40px;
    line-height: 40px;
    min-width: 150px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    padding: 0 20px;
    cursor: pointer;
    &:hover {
      color: #87b2eb;
      background: #f9fbff;
    }
  }
  .active {
    background: #eef5fe;
    color: #1a73e8;
  }
}
</style>
