<template>
  <div class="pageCon">
    <HomePageTop ref="pagetop"></HomePageTop>

    <div class="bg1 ">
      <div class="bg1Con">
        <h3>GESTIONAMOS TODO POR TI</h3>
        <h2>Gestionamos toda su logística internacional</h2>
        <div class="pBox">
          <p>
            Rakumart gestiona y ejecuta todo el proceso logístico de importación
            desde China para usted.
          </p>
          <p>
            Revolucione la forma en que compra e importa de China con todos los
            recursos
          </p>
          <p>que Rakumart tiene para ofrecer.</p>
          <p>Ahorre tiempo y costos para su negocio.</p>
        </div>
      </div>
    </div>

    <div class="whiteBg fontModel">
      <div class="modelCon" style="  position: relative;">
        <fixedMenu :textData="textData" @scrollToTop="scrollToTop" />
        <div class="fontConTitle">
          <span>Logistics naphase en un vistazo</span>
          <div class="line"></div>
        </div>
        <div class="pagetableBox tableBox  showLositiTable ">
          <div class="tul ttt">
            <div class="tli ttt ">TRANSPORTE</div>
            <div class="tli ttt ">VOLUMEN MÍNIMO</div>
            <div class="tli ttt ">VOLUMEN MÁXIMO</div>
            <div class="tli ttt ">TIEMPO DE TRANSPORTE*</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo fontWeight500">Aéreo</div>
            <div class="tli tbo ">VOLUMEN Sin mínimo</div>
            <div class="tli tbo ">Sin máximo</div>
            <div class="tli tbo ">10-20 Días aproximadamente</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo fontWeight500">Terrestre</div>
            <div class="tli tbo ">13kg</div>
            <div class="tli tbo ">Sin máximo</div>
            <div class="tli tbo ">35-45 Días aproximadamente</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo fontWeight500">Marítimo</div>
            <div class="tli tbo ">10m3</div>
            <div class="tli tbo ">Sin máximo</div>
            <div class="tli tbo ">60-70 Días aproximadamente</div>
          </div>
          <div class="tul tbo">
            <div class="tli tbo fontWeight500">Ferroviario</div>
            <div class="tli tbo ">21kg</div>
            <div class="tli tbo ">Sin máximo</div>
            <div class="tli tbo ">45-55 Días aproximadamente</div>
          </div>
        </div>
        <div class="fontConTip">
          *Los tiempos de transporte hacen referencia específicamente al periodo
          requerido por la compañía logística para transportar la mercancía
          desde su punto de origen hasta su punto de destino. Por lo tanto, el
          «tiempo de transporte» no debe confundirse con el «tiempo de entrega
          total». Estos tiempos son aproximados y pueden variar dependiendo de
          factores externos como el clima, regulaciones aduaneras, entre otros.
          Puedes consultar más información sobre logística y envíos haciendo
          <span class="aLink" @click="openChat">clic aquí</span>
        </div>
      </div>
    </div>

    <div class="whiteBg fontModel pabo50">
      <div class="modelCon">
        <div class="fontConTitle">
          <span>Logística internacional flete estimado</span>
          <div class="line"></div>
        </div>
        <div class="pagetableBox tableBox  calculateLogisticsTable">
          <div class="tul ttt">
            <div class="tli ttt ">Peso real</div>
            <div class="tli ttt ">Larga</div>
            <div class="tli ttt ">Ancho</div>
            <div class="tli ttt ">Alto</div>
            <div class="tli ttt ">Número de cajas</div>
            <div class="tli ttt ">Peso total</div>
            <div class="tli ttt" style="flex:0 0 60px;"></div>
          </div>
          <div class="tul tbo" v-for="(item, index) in oform" :key="index">
            <div class="tli tbo ">
              <el-input
                :oninput="$fun.rejectIntNumbers"
                class="logisticsInputBox"
                @change="calculationLogisticsAmount()"
                v-model="item.weight"
                ><template slot="append"><span>KG</span></template></el-input
              >
            </div>
            <div class="tli tbo ">
              <el-input
                :oninput="$fun.rejectIntNumbers"
                class="logisticsInputBox"
                v-model="item.length"
                @change="calculationLogisticsAmount()"
                ><template slot="append"><span>CM</span></template></el-input
              >
            </div>
            <div class="tli tbo ">
              <el-input
                :oninput="$fun.rejectIntNumbers"
                class="logisticsInputBox"
                @change="calculationLogisticsAmount()"
                v-model="item.width"
                ><template slot="append"><span>CM</span></template></el-input
              >
            </div>
            <div class="tli tbo ">
              <el-input
                :oninput="$fun.rejectIntNumbers"
                class="logisticsInputBox"
                v-model="item.height"
                @change="calculationLogisticsAmount()"
                ><template slot="append"><span>CM</span></template></el-input
              >
            </div>
            <div class="tli tbo ">
              <el-input
                :oninput="$fun.rejectIntNumbers"
                class="logisticsInputBox"
                v-model="item.num"
                @change="calculationLogisticsAmount()"
                ><template slot="append"><span></span></template
              ></el-input>
            </div>
            <div class="tli tbo ">
              <el-input
                class="logisticsInputBox totalWeightInput"
                v-model="item.total_weight"
                disabled
                ><template slot="append"><span>KG</span></template></el-input
              >
            </div>
            <div class="tli tbo" style="flex:0 0 60px;">
              <img
                :src="require('@/assets/icon/delete.png')"
                alt=""
                style="width: 17px;cursor:pointer"
                @click="oform.splice(index, 1)"
              />
            </div>
          </div>
          <div class="addRow">
            <el-button class="bgGreenBtn" @click="oform.push({})">{{
              $fanyi("增加")
            }}</el-button>
            <div class="totalBox">
              {{ $fanyi("总计") }}：<b>{{ total_weight }}KG</b>
            </div>
          </div>
        </div>

        <div class="pagetableBox tableBox  calculationResultsTable">
          <div class="tul ttt">
            <div class="tli ttt " style="flex: 0 0 190px;">Forma de envío</div>
            <div class="tli ttt " style="flex: 0 0 190px;">Categorías</div>
            <div class="tli ttt ">
              Peso facturable <br />
              (KG)
            </div>
            <div class="tli ttt ">Envío internacional <br />(¥)</div>
            <div class="tli ttt ">
              Envío internacional <br />
              (€)
            </div>
            <div class="tli ttt ">Plazo</div>
            <div class="tli ttt " style="flex: 0 0 95px;"></div>
          </div>
          <div class="tul tbo" v-for="(item, index) in datas" :key="index">
            <!-- Forma de envío -->
            <div class="tli tbo texcen" style="flex: 0 0 190px;">
              {{ item.logistics_name }}
            </div>
            <!-- Categorías -->
            <div class="tli tbo " style="flex: 0 0 190px;">
              <div class="logisticsNameColorBox" :class="logisticsColor[index]">
                {{ item.prefix }}
              </div>
            </div>
            <!-- Peso facturable  -->
            <div class="tli tbo ">{{ item.charge_weight }}</div>
            <!-- Envío internacional -->
            <div class="tli tbo ">
              {{ $fun.RMBNumSegmentation(item.international_freight_rmb) }}
            </div>
            <!-- Envío internacional -->
            <div class="tli tbo ">
              {{ $fun.EURNumSegmentation(item.international_freight_eu) }}
            </div>
            <!-- Plazo -->
            <div class="tli tbo ">{{ item.attention }}</div>
            <div class="tli tbo " style="flex: 0 0 95px;">
              <span class="aLink" @click="scrollToTop(item.logistics_name)"
                >Detalles</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fontModel pabo50 fdcolmun">
      <div class="modelCon">
        <div class="fontConTitle">
          <span>Explicación de las cargas logísticas</span>
          <div class="line"></div>
        </div>
        <div
          class="whiteCard"
          v-for="(item, index) in textData"
          :key="index"
          :ref="item.name"
        >
          <div class="logisticsNameBox">{{ item.name }}</div>
          <div class="logisticsPriceList pagetableBox ">
            <div class="tul tbo">
              <div class="tli tbo blueTbo">Peso</div>
              <div
                class="tli tbo"
                v-for="(range_item, range_index) in item.range"
                :key="range_index"
              >
                {{ range_item.min }}～{{ range_item.max }}KG
              </div>
            </div>
            <div class="tul tbo">
              <div class="tli tbo blueTbo">Gastos（￥）</div>
              <div
                class="tli tbo"
                v-for="(range_item, range_index) in item.range"
                :key="range_index"
              >
                ￥{{ range_item.price }}/KG
              </div>
            </div>
          </div>
          <div class="lastUpdatedDate">
            Fecha de última actualización：{{ item.updated_at }}
          </div>
          <div class="deliveryTime">
            <span>Plazo</span><b>Entre {{ item.predict_duration }} días</b>
          </div>
          <div class="mattersNeedingAttention">
            <div v-html="item.note_en"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="whiteBg fontModel pabo50">
      <div class="expertAgent pageMoudle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          style="enable-background: new 0 0 24 24"
          xml:space="preserve"
        >
          <path
            class="st0"
            d="M11.4,1C6.6,1.3,3,5.5,3,10.3V17c0,1.7,1.3,3,3,3h1c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2H5v-1.7 C5,6.4,8,3.1,11.8,3c4-0.1,7.2,3.1,7.2,7v2h-2c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h2v1h-6c-0.6,0-1,0.5-1,1s0.4,1,1,1h5 c1.7,0,3-1.3,3-3V10C21,4.8,16.6,0.7,11.4,1z"
          ></path>
        </svg>
        <div class="expertAgentCon">
          <h1>Contacta con un agente experto</h1>
          <p>
            ¿Estás buscando la mejor solución para tus importaciones desde
            China? Rakumart es la respuesta, nosotros nos encargamos de todo,
            desde la compra del producto hasta la recepción en la dirección de
            entrega. Contacta con un agente experto y empieza a beneficiarte de
            todas nuestras ventajas.
          </p>
          <button @click="openChat">Contacta con un agente</button>
        </div>
      </div>
    </div>
    <Foot style="width: 100%"></Foot>
  </div>
</template>
<script>
import fixedMenu from "./components/fixedMenu.vue";
import HomePageTop from "@/components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";
export default {
  data() {
    return {
      logisticsColor: ["color1", "color2", "color3", "color4"],
      oform: [
        {
          weight: 1,
          length: 58,
          width: 50,
          height: 51,
          num: 1,
        },
      ],
      total_weight: 0,
      datas: [],
      textData: [],
    };
  },
  components: {
    HomePageTop,
    Foot,
    fixedMenu,
  },
  computed: {},
  mounted() {},
  created() {
    this.calculationLogisticsAmount();
    this.logisticsAmountExplain();
  },
  methods: {
    openChat() {
      this.$fun.clickJs();
    },
    calculationLogisticsAmount() {
      let total_weight = 0;
      for (let index in this.oform) {
        this.oform[index].total_weight = this.$fun.ceil(
          (Number(this.oform[index].num) || 0) *
            (Number(this.oform[index].weight) || 0)
        );
        this.oform.splice(0, 0);
        total_weight += this.oform[index].total_weight;
      }
      this.total_weight = total_weight;

      // // 验证这五个参数都不为空的时候才调用接口
      let regArr = ["weight", "length", "width", "height", "num"];
      for (let i in this.oform) {
        for (let j in regArr) {
          if (!this.$fun.checkParams(this.oform[i][regArr[j]])) {
            return;
          }
        }
      }

      this.$api.calculationLogisticsAmount({ list: this.oform }).then((res) => {
        ////console.log('事件名',res)
        // if (res.code != 0)
        //   return this.$message.error(this.$fanyi(res.data.msg));
        //接下来的操作
        this.datas = res.data;
        if (this.textData.length) {
          this.getLocation();
        }
      });
    },
    logisticsAmountExplain() {
      this.$api.logisticsAmountExplain().then((res) => {
        ////console.log('事件名',res)
        // if (res.code != 0)
        //   return this.$message.error(this.$fanyi(res.data.msg));
        //接下来的操作
        this.textData = res.data;
        this.$nextTick(() => {
          this.getLocation();
        });
      });
    },

    // 获取位置
    getLocation() {
      for (let i in this.textData) {
        let txtItem = this.textData[i];
        // console.log("元素高度", this.$refs[txtItem.name][0].offsetTop + 820);
        txtItem.offsetTop = this.$refs[txtItem.name][0].offsetTop + 820;
      }
    },

    scrollToTop(name) {
      let i = this.textData.findIndex((ii) => ii.name == name);

      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: this.textData[i].offsetTop,
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
$conMinWidth: 1200px;
.pageCon {
  min-width: 1470px;
  background-color: #f9f9f9;
}

.modelpaddingTop {
  padding-top: 50px;
}
.pabo50 {
  padding-bottom: 50px;
}
.whiteBg {
  background-color: white;
}

.logisticsInputBox {
  border-radius: 4px;
  width: 150px;
  /deep/.el-input__inner {
    border-right: none;
    border-color: #c0c4cc;
  }
  /deep/.el-input-group__append {
    background-color: white;
    color: #7d7f8f;
    font-size: 14px;
    border-color: #c0c4cc;
    padding-right: 16px;
  }
}
.logisticsInputBox.totalWeightInput {
  /deep/.el-input__inner {
    background-color: transparent;
  }
}

.bg1 {
  background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202412/6763b14205ce6.png");
  background-size: 100% 100%;
  height: 560px;
  @extend .dip;
  .bg1Con {
    width: $conMinWidth;
    line-height: 1;
    color: #ffffff;
    h3 {
      font-size: 12px;
      color: #ffad00;
      letter-spacing: 2.2px;
      margin-bottom: 25px;
    }
    h2 {
      font-size: 26px;
      margin-bottom: 25px;
      letter-spacing: 0.2px;
    }
    .pBox {
      p {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}

.fontModel {
  @extend .modelpaddingTop;
  @extend .dip;

  .modelCon {
    width: $conMinWidth;
    .fontConTitle {
      height: 56px;
      @extend .dip;
      margin-bottom: 26px;
      span {
        font-size: 24px;
        margin-right: 60px;
        line-height: 56px;
      }
      .line {
        height: 1px;
        background-color: #b4b4b4;
        flex: 1;
      }
    }

    .tableBox {
      margin-bottom: 0;
      .tul {
        .tli {
          border: none !important;
          padding: 20px 0;
        }

        .tli.ttt {
          background: none;
          height: 53px;
          font-weight: 500;
          font-size: 13px;
          color: #7d7f8f;
          letter-spacing: 2.6px;
        }
        .tli.tbo {
          min-height: 68px;
          color: #808191;
          font-size: 16px;
        }

        .tli.fontWeight500 {
          font-weight: 500;
          color: #000000;
        }
      }
      .tul.ttt {
        background-color: #f0f3f6;
        border-radius: 6px;
      }
      .tul.tbo {
        background-color: white;
      }
      .tul.tbo:nth-child(2n-1) {
        background-color: #f9f9fb;
      }
    }

    .whiteCard {
      background-color: white;
      margin-bottom: 30px;
      border-radius: 4px;
      &:last-child {
        margin-bottom: 0;
      }
      padding: 10px 23px;
      .logisticsNameBox {
        font-weight: bold;
        font-size: 30px;
        line-height: 56px;
        margin-bottom: 10px;
      }
    }

    .showLositiTable {
      .tul {
        padding: 0 30px;
      }
    }

    .calculateLogisticsTable {
      margin-bottom: 30px;

      .addRow {
        @extend.dip;
        justify-content: space-between;
        padding: 0 145px 0 20px;
        height: 68px;
        background: #f9f9fb;
        border: 1px solid #f0f3f6;
        .el-button {
          border-radius: 4px;
          height: 42px;
          width: 100px;
          padding-left: 0;
          padding-right: 0;
        }
        .totalBox {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .calculationResultsTable {
      .tul {
        border: 1px solid #f0f3f6;
        margin-top: -1px;
        .tli {
          padding: 20px 10px;
        }
        .tli.ttt {
          text-align: center;
          letter-spacing: 2.2px;
          line-height: 1.1;
        }
        .tli.tbo {
          font-size: 16px;
          color: #000000;
          white-space: pre-line;
        }

        .logisticsNameColorBox {
          @extend .dip;
          width: 100%;
          border-radius: 4px;
          height: 40px;
          font-weight: 600;
          font-size: 14px;
        }
        .aLink {
          font-weight: 600;
          font-size: 14px;
          color: #1a73e8;
          text-decoration: none;
        }
        .texcen {
          text-align: center;
          word-break: normal;
        }
      }
    }

    .fontConTip {
      padding: 30px 30px 15px 30px;
      font-size: 15px;
      color: #808191;
      line-height: 26px;
      .aLink {
        color: #1a289d;
        text-decoration: none;
      }
    }

    .logisticsPriceList {
      margin-bottom: 15px;
      .tul {
        width: max-content;
        background-color: white;
        .tli.tbo {
          flex: unset;
          color: #000000;
          border-color: #f0f3f6;
          min-height: 68px;
          font-weight: 500;
          width: 200px;
        }
        .tli.tbo.blueTbo {
          background: #1a73e8;
          color: white;
          width: 160px;
        }
      }
    }

    .lastUpdatedDate {
      line-height: 18px;
      font-size: 14px;
      color: #808191;
      margin-bottom: 27px;
    }

    .deliveryTime {
      width: 240px;
      height: 42px;
      background: #eef5fe;
      border-radius: 21px;
      @extend .dip;
      font-size: 16px;
      color: #1a73e8;
      margin-bottom: 26px;
      span {
        font-weight: 600;
        margin-right: 14px;
      }
    }

    .mattersNeedingAttention {
      font-size: 16px;
      color: #000000;
      line-height: 24px;
      margin-bottom: 15px;
    }
  }
}

.expertAgent {
  width: 1200px;
  height: 451.08px;
  background-color: #f8f9fa;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  @extend .fdcolmun;
  justify-content: center;
  svg {
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
    fill: #99aef0;
  }
  .expertAgentCon {
    width: 660px;
    padding: 10px;
    margin: 0 auto;
    > * {
      text-align: center;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    h1 {
      font-size: 21px;
      font-weight: 500;
      line-height: 1.5em;
      margin-bottom: 10px;
    }
    p {
      width: 660px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8em;
      margin-bottom: 34px;
    }
    button {
      font-size: 15px;
      line-height: 15px;
      font-weight: 600;
      padding: 20px 80px 20px 80px;
      border-radius: 4px;
      border: #1a73e8 solid 2px;
      color: #1a73e8;
      &:hover {
        color: #ffffff;
        background-color: #1a73e8;
        border-color: #1a73e8;
      }
    }
  }
}

.color1 {
  background: #eef5fe;
  color: #1a73e8;
}
.color2 {
  background: #e7fafb;
  color: #19c2cd;
}
.color3 {
  background: #eaffef;
  color: #2ec353;
}
.color4 {
  background: #fff4e8;
  color: #ffa81e;
}
.color5 {
  background: #fceefe;
  color: #d71ae8;
}
.color6 {
  background: #fefaee;
  color: #e8a71a;
}
.color7 {
  background: #fef2ee;
  color: #e85b1a;
}
</style>
