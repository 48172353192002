<template>
  <div class="payStepBox">
    <!-- <span v-if="isPeiSong">{{ $fanyi("Envio仓库") }}</span>
        <span v-else>{{ $fanyi("进口") }}</span> -->
    <div class="stepOne stepopt active">
      <div class="round">
        <span>{{ $fanyi("产品付款") }}</span>
      </div>
      <div class="transverseLine"></div>
    </div>
    <div
      class="stepOne stepopt"
      :class="{ active: $parent.step == 2 || !isPeiSong }"
    >
      <div class="round">
        <span>{{ $fanyi("Envio仓库") }}</span>
      </div>
      <div class="transverseLine"></div>
    </div>
    <div class="stepOne stepopt" :class="{ active: !isPeiSong }">
      <div class="round">
        <span>{{ $fanyi("国际航运付款") }}</span>
      </div>
      <div class="transverseLine"></div>
    </div>
    <div
      class="stepOne stepopt"
      :class="{ active: $parent.step == 2 && !isPeiSong }"
    >
      <div class="round">
        <span>{{ $fanyi("进口") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    isPeiSong() {
      return !this.$parent.isPeiSongDan;
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../css/mixin.scss";
.payStepBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  .stepopt {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .round {
      width: 36px;
      height: 36px;
      background: #e2e2e2;
      border-radius: 50%;
      position: relative;
      span {
        position: absolute;
        bottom: -41px;
        left: 50%;
        transform: translate(-50%, 0);
        white-space: nowrap;
      }
      &::after {
        content: "";
        width: 24px;
        height: 24px;
        background: #ffffff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .transverseLine {
      width: 300px;
      height: 3px;
      background-color: #e2e2e2;
    }

    &.active {
      .round {
        background-color: #212a91;

        &:after {
          content: "✔";
          color: white;
          text-align: center;
          background-color: #212a91;
        }
      }
      .transverseLine {
        background-color: #212a91;
      }
    }
  }
}
</style>
