import { render, staticRenderFns } from "./InFormDetailEmail.vue?vue&type=template&id=204319e6&scoped=true&"
import script from "./InFormDetailEmail.vue?vue&type=script&lang=js&"
export * from "./InFormDetailEmail.vue?vue&type=script&lang=js&"
import style0 from "./InFormDetailEmail.vue?vue&type=style&index=0&id=204319e6&lang=scss&scoped=true&"
import style1 from "../../../../css/quillcore.css?vue&type=style&index=1&id=204319e6&scoped=true&lang=css&"
import style2 from "../../../../css/quillbubble.css?vue&type=style&index=2&id=204319e6&scoped=true&lang=css&"
import style3 from "../../../../css/quillsnow.css?vue&type=style&index=3&id=204319e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204319e6",
  null
  
)

export default component.exports