<template>
  <div style="background: white">
    <Head></Head>
    <div class="page">
      <h1>Política de cookies</h1>
      <h1>¿Qué son las cookies?</h1>
      <p>
        Las cookies son pequeños archivos de datos que se reciben en el terminal
        desde un sitio web visitado y se usan para registrar ciertas
        interacciones de la navegación en un sitio web almacenando datos que
        podrán ser actualizados y recuperados.
      </p>
      <p>
        Estos archivos se almacenan en el ordenador del usuario y contienen
        datos anónimos que no son perjudiciales para su equipo. Se utilizan para
        recordar las preferencias del usuario, como el idioma seleccionado, los
        datos de acceso o la personalización.
      </p>
      <p>
        Las cookies también pueden ser utilizadas para registrar información
        anónima sobre como el visitante utiliza el sitio web. Por ejemplo, desde
        que página web se ha accedido o si ha utilizado un banner publicitario
        para llegar a esta.
      </p>
      <h1>¿Qué usos tienen los diferentes tipos de cookies?</h1>
      <p>Según su finalidad:</p>
      <h1>Cookies técnicas</h1>
      <p>
        Las cookies técnicas son aquellas imprescindibles y estrictamente
        necesarias para el funcionamiento correcto de un sitio web y la
        utilización de las diferentes opciones y servicios que ofrece. Por
        ejemple, las que sirven para el mantenimiento de la sesión, gestión del
        tiempo de respuesta, rendimiento o validación de opciones, uso de
        elementos de seguridad, compartición de contenido con redes sociales,
        etc.
      </p>
      <h1>Cookies de personalización</h1>
      <p>
        Estas cookies permiten al usuario especificar o personalizar algunas
        características de las opciones generales de la página web. Por ejemple,
        definir el idioma, la configuración regional o el tipo de navegador.
      </p>
      <h1>Cookies analíticas</h1>
      <p>
        Las cookies analíticas son las utilizadas por nuestro sitio web para
        elaborar perfiles de navegación y poder conocer las preferencias de los
        usuarios con la finalidad de mejorar la oferta de productos y servicios.
        Por ejemplo, mediante una cookie analítica se controlarían las áreas
        geográficas de más interés de un usuario, cual es el producto de más
        aceptación, etc.
      </p>
      <h1>Cookies publicitarias / de publicidad</h1>
      <p>
        Las cookies publicitarias permiten la gestión de los espacios
        publicitarios de acuerdo con criterios concretos. Por ejemple, la
        frecuencia de acceso, el contenido editado, etc. Las cookies
        publicitarias permiten, a través de la gestión de la publicidad,
        almacenar información del comportamiento a través de la observación de
        hábitos, estudiando los accesos y formando un perfil de preferencias del
        usuario, para ofrecerle publicidad relacionada con los intereses de su
        perfil.
      </p>
      <p>Según plazo:</p>
      <h1>Cookies de sesión</h1>
      <p>
        Las cookies de sesión son aquellas que durante el tiempo que el usuario
        esta navegando por la página web y se borran cuando termina.
      </p>
      <h1>Cookies persistentes</h1>
      <p>
        Estas cookies quedan almacenadas en el terminal del usuario durante un
        tiempo más largo, facilitando así el control de las preferencias
        escogidas sin tener que repetir ciertos parámetros cada vez que se
        visite el sitio web.
      </p>
      <h1>¿Por qué utilizamos cookies?</h1>
      <p>
        Utilizamos las cookies estrictamente necesarias y esenciales para que
        usted utilice nuestro sitio web y pueda moverse libremente, utilizar
        áreas seguras, configurar opciones personalizadas, etc. También
        utilizamos cookies que recogen datos relativos al análisis de uso de la
        web. Estas se utilizan para ayudar a mejorar la experiencia de uso del
        usuario y el rendimiento de la página.
      </p>
      <p style="margin-bottom: 35px">
        En algunos sitios de la web se pueden instalar cookies de terceros.
        Nosotros no controlamos las cookies utilizadas por estos terceros en
        webs externas. Para más información sobre las cookies de webs ajenas
        aconsejamos de revisar sus propias políticas de cookies. <br />
        Las cookies utilizadas por este sitio son:
      </p>
      <div class="mytable">
        <div class="tul ttt tabrow">
          <div class="tli ttt Cookie">Cookie</div>
          <div class="tli ttt Procedencia">Procedencia</div>
          <div class="tli ttt Proposito">Propósito</div>
          <div class="tli ttt Caducidad">Caducidad</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">1p_jar</div>
          <div class="tli tbo Procedencia">google.com</div>
          <div class="tli tbo Proposito">
            Cookie analítica, transfiere datos a Google.
          </div>
          <div class="tli tbo Caducidad">1 mes</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">ANID, AID</div>
          <div class="tli tbo Procedencia">google.com</div>
          <div class="tli tbo Proposito">Cookie publicitaria</div>
          <div class="tli tbo Caducidad">1 año</div>
        </div>
        <div class="tul tbo tabrow gray">
          <div class="tli tbo Cookie">CONSENT</div>
          <div class="tli tbo Procedencia">___</div>
          <div class="tli tbo Proposito">
            Cookie de Google Maps, se usa para el funcionamiento del mapa de
            Google en la página de contacto.
          </div>
          <div class="tli tbo Caducidad">Indefinida</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">NID</div>
          <div class="tli tbo Procedencia">google.com</div>
          <div class="tli tbo Proposito">Cookie publicitaria</div>
          <div class="tli tbo Caducidad">6 meses</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">IDE</div>
          <div class="tli tbo Procedencia">doubleclick.net</div>
          <div class="tli tbo Proposito">
            Utilizada por Google DoubleClick para registrar e informar sobre las
            acciones del usuario en el sitio web tras visualizar o hacer clic en
            uno de los anuncios del anunciante con el propósito de medir la
            eficacia de un anuncio y presentar anuncios específicos para el
            usuario.
          </div>
          <div class="tli tbo Caducidad">1 año 7 meses</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">_gads, _sonar</div>
          <div class="tli tbo Procedencia">doubleclick.net</div>
          <div class="tli tbo Proposito">Cookie publicitaria</div>
          <div class="tli tbo Caducidad">1 año</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">_fbp</div>
          <div class="tli tbo Procedencia">doubleclick.net</div>
          <div class="tli tbo Proposito">Cookie publicitaria</div>
          <div class="tli tbo Caducidad">3 meses</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">cto_lwid</div>
          <div class="tli tbo Procedencia">doubleclick.net</div>
          <div class="tli tbo Proposito">Cookie publicitaria</div>
          <div class="tli tbo Caducidad">13 meses</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">_ga</div>
          <div class="tli tbo Procedencia">____</div>
          <div class="tli tbo Proposito">
            Se usa para distinguir a los usuarios.
          </div>
          <div class="tli tbo Caducidad">2 años</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">_gid</div>
          <div class="tli tbo Procedencia">____</div>
          <div class="tli tbo Proposito">
            Se trata de una cookie analítica, relacionada con Google Analytics
            que se usa para distinguir a los usuarios.
          </div>
          <div class="tli tbo Caducidad">1 día</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">_gat</div>
          <div class="tli tbo Procedencia">____</div>
          <div class="tli tbo Proposito">
            Se usa para diferenciar entre los diferentes objetos de seguimiento
            creados en la sesión. La cookie se crea al cargar la librería
            javascript y no existe una versión previa de la cookie _gat
          </div>
          <div class="tli tbo Caducidad">Sesión</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">_cookieconsent</div>
          <div class="tli tbo Procedencia">____</div>
          <div class="tli tbo Proposito">
            Almacena el estado de consentimiento de cookies del usuario para el
            dominio
          </div>
          <div class="tli tbo Caducidad">1 año</div>
        </div>
        <div class="tul tbo tabrow">
          <div class="tli tbo Cookie">_cfduid</div>
          <div class="tli tbo Procedencia">workable</div>
          <div class="tli tbo Proposito">Identifica tráfico de confianza</div>
          <div class="tli tbo Caducidad">1 año</div>
        </div>
        <!-- <div class="tul tNullData" v-if="boxSon.length == 0">数据为空</div> -->
      </div>
      <h1>¿Cómo administrar las cookies?</h1>
      <p>
        Para utilizar este sitio web resulta necesaria la instalación de
        cookies. Tiene que tener en cuenta que si r<br />
        o borra las cookies de navegación, no podremos mantener sus preferencias
        y es posible que algunas<br />
        características de las páginas web no estén operativas.<br />
        Usted puede revocar el consentimiento otorgado cesando la navegación, no
        aceptarlas o configurar su<br />
        navegador para bloquearlas, y en su caso eliminarlas. Más información
        para cada navegador:
      </p>
      <p class="blue">Internet Explorer 8 y superior; InPrivate</p>
      <p class="blue">Safari 2 y superior; Navegación Privada</p>
      <p class="blue">Opera 10.5 y superior; Navegación Privada</p>
      <p class="blue">FireFox 3.5 y superior; Navegación Privada</p>
      <p class="blue" style="margin-bottom: 70px">
        Google Chrome 10 y superior; Incógnito
      </p>
    </div>
    <div id="cookiefirst-policy-page"></div>
    <div class="centr">
      Esta política de cookies ha sido creada y actualizada por
      <a href=" ">CookieFirst.com</a>.
    </div>
    <div id="cookiefirst-cookies-table"></div>
    <div class="centr">
      Esta tabla de cookies ha sido creada y actualizada por el
      <a href=" ">CookieFirst consent management platform</a>.
    </div>
    <Foot style="width: 100%"></Foot>
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";
export default {
  data() {
    return {};
  },
  components: { Head, Foot },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.page {
  width: 750px;
  margin: 0 auto;
  padding: 10px;
  h1 {
    font-size: 14px;
    font-weight: bolder;
    margin-bottom: 14.4px;
  }
  p {
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 14.4px;
    &.blue {
      color: #0000ff;
    }
  }
  .mytable {
    margin-bottom: 40px;
    > .tul {
      display: flex;
      // 表头
      &.ttt {
      }
      // 内容
      &.tbo {
        background-color: #f9f9f9;
        &:nth-child(2n) {
          background-color: #fff;
        }
        &.gray {
          background-color: #f3f3f3;
        }
        &:hover {
          background-color: #f3f3f3;
        }
      }
      //   &.tfoot {
      //     min-height: 60px;
      //     display: flex;
      //     align-items: center;
      //     border: solid 1px #ebeef5;
      //     padding: 10px;
      //     .el-button {
      //       margin-right: 15px;
      //     }
      //   }
      // 单个单元格
      .tli {
        flex: 1;

        &.ttt {
          border: none;
          background-color: #d9edf7;
          height: 38.59px;
          display: flex;
          align-items: center;
          font-weight: 700;
          padding: 8px;
          font-size: 14.4px;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        }
        &.tbo {
          display: flex;
          font-size: 14.4px;
          padding: 8px 0px 8px 8px;
          border-top: #ebeef5 solid 1px;
          line-height: 1.5;
        }
        &.Cookie {
          flex: 0 0 113.54px;
        }
        &.Procedencia {
          flex: 0 0 111.63px;
        }
        &.Proposito {
          flex: 0 0 414.2px;
        }
        &.Caducidad {
          flex: 0 0 90.64;
        }
      }
    }
    .tNullData {
      font-size: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      color: #bbbec7;
      border: #ebeef5 solid 2px;
    }
  }
}
#cookiefirst-cookies-table {
  width: 1065px;
  margin: 0 auto 20px;
}
#cookiefirst-policy-page {
  width: 1065px;
  margin: 0 auto 20px;
}
.centr {
  width: 730px;
  margin: 0 auto 20px;
}
</style>
