<template>
  <div id="userOrder">
    <Dialog ref="dialog" :config="config" />
    <div>
      <div class="searchBox">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.searchText"
            @keydown.enter="getData()"
            :placeholder="$fanyi('请输入关键词搜索')"
          />
        </div>
        <button
          class="searchBtn"
          @click="
            form.page = 1;
            getData(form);
          "
        >
          {{ $fanyi("搜索") }}
        </button>
      </div>
      <!-- 表格 -->
      <div class="dataBody">
        <el-table
          :data="tableData"
          border
          :key="tableKey"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            background: '#F0F0F0',
            color: 'black',
            'font-size': '12px',
            'font-weight': '500',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <!-- Nº -->
          <el-table-column type="index" width="50" label="Nº">
          </el-table-column>
          <!-- 配送单号 -->
          <el-table-column prop="name" :label="$fanyi('配送单号')" width="190">
            <template slot="header">
              <div class="tableHeader" :title="$fanyi('配送单号')">
                {{ $fanyi("配送单号") }}
              </div>
            </template>
            <template v-slot="scope">
              <button
                class="porder_sn"
                @click="
                  $fun.routerToPage(
                    '/deliveryDetails?order_sn=' + scope.row.porder_sn
                  )
                "
              >
                {{ scope.row.porder_sn }}
              </button>
            </template>
          </el-table-column>
          <!-- 西班牙物流 -->
          <el-table-column :label="$fanyi('西班牙物流')" width="160">
            <template slot="header">
              <div class="tableHeader" :title="$fanyi('西班牙物流')">
                {{ $fanyi("西班牙物流") }}
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                <p
                  v-for="(item, index) in scope.row.porder_foreign_express"
                  :key="index"
                >
                  {{ item.foreign_express }}:
                  <span style="color: #ffa724">{{
                    item.foreign_express_no
                  }}</span>
                </p>
              </div>
            </template>
          </el-table-column>
          <!-- 签收时间 -->
          <el-table-column :label="$fanyi('签收时间')" width="150">
            <template slot="header">
              <div class="tableHeader" :title="$fanyi('签收时间')">
                {{ $fanyi("签收时间") }}
              </div>
            </template>
            <template v-slot="scope">
              <div
                v-if="
                  scope.row.porder_time_node != null &&
                    scope.row.porder_time_node.datetime_client_receive != null
                "
              >
                {{ scope.row.porder_time_node.datetime_client_receive }}
              </div>
            </template>
          </el-table-column>
          <!-- 通关方式 -->
          <el-table-column :label="$fanyi('海关')" width="150">
            <template slot="header">
              <div class="tableHeader" :title="$fanyi('海关')">
                {{ $fanyi("海关") }}
              </div>
            </template>
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.porder_time_node != null &&
                    scope.row.porder_time_node.datetime_cleared != null
                "
              >
                {{ scope.row.porder_time_node.datetime_cleared }}
              </div>
            </template>
          </el-table-column>
          <!-- 国际运输方式 -->
          <el-table-column
            prop="logistics"
            :label="$fanyi('国际运输方式')"
            width="159"
          >
            <template slot="header">
              <div class="tableHeader" :title="$fanyi('国际运输方式')">
                {{ $fanyi("国际运输方式") }}
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                {{ $fanyi(scope.row.status_name) }}
              </div>
            </template>
          </el-table-column>
          <!-- 箱数 -->
          <el-table-column :label="$fanyi('箱数')" width="70">
            <template v-slot="scope">
              <div>{{ scope.row.porder_freight.length }}</div>
            </template>
          </el-table-column>
          <!-- 体积(m³) -->
          <el-table-column
            prop="billing_parameter"
            :label="$fanyi('体积') + '(m³)'"
            width="80"
          >
            <template slot="header">
              <div class="tableHeader" :title="$fanyi('体积') + '(m³)'">
                {{ $fanyi("体积") + "(m³)" }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--        <el-table-->
        <!--            :data="tableData"-->
        <!--            border-->
        <!--            :key="tableKey"-->
        <!--            :header-cell-style="{-->
        <!--                    background: '#F0F0F0',-->
        <!--                    color: 'black',-->
        <!--                    'font-size': '12px',-->
        <!--                    'font-weight': '500',-->
        <!--                  }"-->
        <!--            :cell-style="{ 'text-align': 'center' }"-->
        <!--            style="width: 100%">-->
        <!--          <el-table-column type="index" width="50" label="Nº">-->
        <!--          </el-table-column>-->
        <!--          <el-table-column-->
        <!--              :label="$fanyi('配送单号')"-->
        <!--              width="190"-->
        <!--          >-->
        <!--            <template v-slot="scope">-->
        <!--              <button class="porder_sn" @click="$fun.routerToPage('/deliveryDetails?order_sn=' + scope.row.porder_sn)">-->
        <!--                {{ scope.row.porder_sn }}-->
        <!--              </button>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--          <el-table-column-->
        <!--              :label="$fanyi('西班牙物流')" width="160">-->
        <!--            <template v-slot="scope">-->
        <!--              <div>-->
        <!--                <p-->
        <!--                    v-for="(item, index) in scope.row.porder_foreign_express"-->
        <!--                    :key="index"-->
        <!--                >-->
        <!--                  {{ item.foreign_express }}:-->
        <!--                  <span style="color: #ffa724">{{-->
        <!--                      item.foreign_express_no-->
        <!--                    }}</span>-->
        <!--                </p>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--          <el-table-column-->
        <!--              :label="$fanyi('签收时间')">-->
        <!--            <template v-slot="scope">-->
        <!--              <div v-if="scope.row.porder_time_node.datetime_client_receive!=null">{{scope.row.porder_time_node.datetime_client_receive}}</div>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--          <el-table-column-->
        <!--              :label="$fanyi('海关')">-->
        <!--            <template v-slot="scope">-->
        <!--                            <div v-if="scope.row.porder_time_node.datetime_cleared!=null">-->
        <!--                              {{ scope.row.porder_time_node.datetime_cleared }}-->
        <!--                            </div>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--                    &lt;!&ndash; 国际运输方式 &ndash;&gt;-->
        <!--                    <el-table-column-->
        <!--                      prop="logistics"-->
        <!--                      :label="$fanyi('国际运输方式')"-->
        <!--                      width="150"-->
        <!--                    >-->
        <!--                      <template slot="header">-->
        <!--                        <div class="tableHeader" :title="$fanyi('国际运输方式')">-->
        <!--                          {{ $fanyi("国际运输方式") }}-->
        <!--                        </div>-->
        <!--                      </template>-->
        <!--                    </el-table-column>-->
        <!--                    &lt;!&ndash; 箱数 &ndash;&gt;-->
        <!--                    <el-table-column :label="$fanyi('箱数')">-->
        <!--                      <template v-slot="scope">-->
        <!--                          <div>{{ scope.row.porder_freight.length }}</div>-->
        <!--                      </template>-->
        <!--                    </el-table-column>-->
        <!--                    &lt;!&ndash; 体积(m³) &ndash;&gt;-->
        <!--                    <el-table-column prop="billing_parameter" :label="$fanyi('体积') + '(m³)'">-->
        <!--                      <template slot="header">-->
        <!--                        <div class="tableHeader" :title="$fanyi('体积') + '(m³)'">-->
        <!--                          {{ $fanyi("体积") + "(m³)" }}-->
        <!--                        </div>-->
        <!--                      </template>-->
        <!--                    </el-table-column>-->
        <!--        </el-table>-->
      </div>
      <div class="allpag">
        <a
          href="javascript:;"
          @click="
            form.page = 1;
            getData();
          "
          >{{ $fanyi("回到首页") }}</a
        >
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="form.page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "../../../../components/public/Dialog.vue";
import { daysDistance, getCurrentDay } from "../../../../utlis/date";

export default {
  data() {
    return {
      getCurrentDay,
      daysDistance,
      form: {
        status: "已签收",
        page: 1,
        pageSize: 10,
        searchText: "",
      },
      tableKey: "tablekey",
      tableData: [
        {
          name: 123,
          porder_time_node: { datetime_client_receive: 1 },
          porder_freight: [],
        },
      ],
      total: 0,
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确定"), this.$fanyi("取消")],
      },
      tableTypeNum: [
        {
          status: "国际运输中", //状态
          num: 0, //数量
        },
        {
          status: "已清关", //状态
          num: 0, //数量
        },
        {
          status: "西班牙派送中", //状态
          num: 1, //数量
        },
        {
          status: "已签收", //状态
          num: 0, //数量
        },
      ], //返回数据
    };
  },
  components: {
    Dialog,
  },
  computed: {},
  created() {
    this.getData();
    this.getTitleNumber();
  },
  methods: {
    // 获取种类数量数据
    getTitleNumber() {
      this.$api.internationalLogisticsOrderNum().then((res) => {
        //console.log("internationalLogisticsOrderNum", res);
        if (res.code != 0) return;
        if (res.data.length == 0) return false;
        this.tableTypeNum = res.data;
      });
    },
    // 获取数据
    getData() {
      this.tableKey = Math.random()
        .toString(36)
        .slice(-8);
      let datas = {
        status: this.form.status,
        pageSize: this.form.pageSize,
        page: this.form.page,
        keywords: this.form.searchText,
      };
      this.$api.storageDeliverList(datas).then((res) => {
        if (res.code != 0) return;
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    qianShou(sn) {
      this.$refs.dialog.open(
        this.$fanyi("是否签收选中的商品？"),
        () => {
          this.$api
            .internationalLogisticsClientReceive({ porder_sn: sn })
            .then((res) => {
              //console.log("internationalLogisticsClientReceive", res);
              if (res.code != 0) return;
              this.$message.success(this.$fanyi("签收成功"));
              this.getData();
              this.getTitleNumber();
            });
        },
        () => {
          this.$message(this.$fanyi("已取消签收"));
        }
      );
    },

    handleCurrentChange(val) {
      this.form.page = val;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
#userOrder {
  @import "../../../../css/mixin";
  width: 100%;

  button {
    cursor: pointer;
  }

  > nav {
    height: 50px;
    display: flex;
    background-color: #f6f6f6;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      height: 50px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      font-size: 12px;

      color: #000000;
      line-height: 26px;

      cursor: pointer;

      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }

      .label {
        display: inline-block;
        margin-right: 7px;
      }

      .numberCount {
        width: 18px;
        height: 18px;
        background: #fbbb21;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        line-height: 18px;
      }
    }
  }

  > div {
    // min-height: 720px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-top: none;
    padding: 20px 20px 37px 20px;

    .searchBox {
      display: flex;
      margin-bottom: 20px;

      .myInput {
        height: 40px;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 20px;

        > i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        > input {
          border: none;
          width: 400px;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          height: 100%;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;

          &:focus {
            border-color: #1e2997;
          }
        }
      }

      /deep/ .el-date-editor {
        border: 1px solid #c0c4cc;
        // width: 230px;
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;

        .el-range-input {
          font-size: 14px;
        }

        .el-range-separator {
          // width: 20px;
        }
      }

      .searchBtn {
        width: 80px;
        height: 40px;
        background: $homePageSubjectColor;
        border-radius: 4px;

        color: #ffffff;
        font-size: 16px;
      }
    }

    .dataBody {
      .porder_sn {
        font-size: 14px;
        color: #1e2997;
        line-height: 20px;
        background-color: transparent;
      }

      .qianShoubox {
        display: flex;
        justify-content: center;

        .qianShou {
          color: #1e2997;
        }

        > div {
          width: 115px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #999999;
          margin-left: 10px;
          line-height: 16px;
        }
      }
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      > a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 2px;
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 3px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px rgba(61, 61, 61, 0.1);

        &:hover {
          color: $homePageSubjectColor;
        }

        &.active {
          background-color: $homePageSubjectColor;
          color: white;
        }
      }
    }
  }
}

.tableHeader {
  font-size: 12px;
  line-height: 14px;
}
</style>
