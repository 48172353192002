<template>
  <div class="bottomOfPaymentPageBox">
    <div class="bottomOfPaymentPageCon">
      <div class="timeBox">
        ©Rakumart 2023 Todos los derechos reservados - RAKUMART INVESTMENT CO.,
        LIMITED
      </div>
      <div class="linkOptBox">
        <span
          @click="$fun.routerToPage('/condiciones-generales-de-contratacion')"
          >{{ $fanyi("一般条件") }}</span
        >
        <span @click="$fun.routerToPage('/aviso-legal')">{{
          $fanyi("法律通知")
        }}</span>
        <span @click="$fun.routerToPage('/politica-privacidad')">{{
          $fanyi("用户协议和隐私政策")
        }}</span>
        <span @click="$fun.routerToPage('/politica-de-cookies')">{{
          $fanyi("Cookie 政策")
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BottomOfPaymentPage",
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.bottomOfPaymentPageBox {
  width: 100%;
  height: 109px;
  margin-top: 80px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  .bottomOfPaymentPageCon {
    margin: 0 auto;
    height: 100%;
    width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
  }
  .timeBox {
    font-size: 14px;
  }
  .linkOptBox {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 14px;
      margin-left: 25px;
      cursor: pointer;
    }
  }
}
</style>
