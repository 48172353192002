<template>
  <div>
    <el-dialog
      :title="$fanyi('商品库添加商品')"
      width="1400px"
      :visible.sync="$parent.addDiaStuts"
    >
      <el-table
        :data="addList"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        cell-class-name="tabcellClassName"
        header-row-class-name="tabcellHeadClassName"
      >
        <el-table-column :label="$fanyi('商品图片')" width="100">
          <template slot-scope="scope">
            <img
              class="addImg"
              v-if="!scope.row.isAdd"
              :src="scope.row.img_url"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column
          property="name"
          :label="$fanyi('商品链接')"
          width="320"
        >
          <template slot-scope="scope">
            <el-input
              class="addTextarea"
              type="textarea"
              :placeholder="$fanyi('请输入商品链接')"
              v-model="scope.row.goods_url"
              :readonly="!scope.row.isAdd"
              @change="searchGoods(scope.row, scope.$index)"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column :label="$fanyi('商品属性')" width="220">
          <template slot-scope="scope">
            <el-popover
              v-if="!scope.row.isAdd"
              placement="bottom"
              width="420"
              trigger="click"
            >
              <div class="chioceDetail">
                <div
                  class="dia"
                  v-for="(item, index) in scope.row.specification"
                  :key="index"
                >
                  <span class="chioceTitle">{{ item.key }}:</span>
                  <span class="chioceBody">
                    <span
                      class="chioceBodyOpt"
                      v-for="(valueItem, valueIndex) in item.value"
                      :class="{ active: item.active == valueIndex }"
                      :key="valueIndex"
                      @click="
                        getColor(
                          valueIndex,
                          item,
                          index,
                          scope.row.specification_checked,
                          scope.$index,
                          scope.row.specification_checked_chinese,
                          scope.row.specificationChinese,
                          scope.row
                        )
                      "
                    >
                      {{ valueItem.name }}
                    </span>
                  </span>
                </div>
              </div>
              <div slot="reference" class="goodsDetail">
                <div class="detail">
                  <div class="detailCon">
                    <p
                      v-for="(item, index) in scope.row.specification"
                      :key="index"
                      :title="item.key + ':' + item.value[item.active].name"
                    >
                      {{ item.key }} : {{ item.value[item.active].name }}
                    </p>
                  </div>
                </div>
                <img
                  src="../../../../../assets/newImg/order/modify.svg"
                  alt=""
                />
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column property="price" :label="$fanyi('单价')" width="160">
          <template slot-scope="scope">
            <span v-if="!scope.row.isAdd">{{ scope.row.price }}￥</span>
          </template>
        </el-table-column>
        <el-table-column property="name" :label="$fanyi('商品状态')">
          <template slot-scope="scope">
            <span v-if="!scope.row.isAdd">{{ $fanyi("正常") }}</span>
          </template>
        </el-table-column>
        <el-table-column property="num" width="158" :label="$fanyi('数量')">
          <template slot-scope="scopeer">
            <div>
              <el-input-number
                size="small"
                v-model="scopeer.row.num"
                :min="1"
                @change="saveGoodsNum(scopeer.row)"
              ></el-input-number>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column property="name" label="附加服务" width="220">
          <template slot-scope="scope">
            <el-popover
              v-if="!scope.row.isAdd"
              placement="bottom"
              width="220"
              trigger="click"
              @after-leave="saveOrderOption(scope.row, scope.$index)"
            >
              <div class="optionsGroup">
                <el-table
                  :data="$parent.optionList"
                  cell-class-name="cellClassName"
                  header-row-class-name="cellHeadClassName"
                  :header-cell-style="{ 'text-align': 'center' }"
                  :cell-style="{ 'text-align': 'center' }"
                >
                  <el-table-column property="date" width="40" label=""
                    ><template slot-scope="scope">
                      <div>
                        <input
                          class="chiose"
                          type="checkBox"
                          :checked="scope.row.checked"
                          @click="scope.row.checked = !scope.row.checked"
                        />
                      </div> </template
                  ></el-table-column>
                  <el-table-column
                    property="name"
                    width="128"
                    label="附加服务选项"
                  ></el-table-column>
                </el-table>
              </div>
              <div
                slot="reference"
                @click="setOrderOption(scope.row.option)"
                class="optionsBox"
              >
                <p
                  class="options"
                  v-for="(optItem, optIndex) in scope.row.option"
                  :key="optIndex"
                  v-show="optItem.checked"
                >
                  {{ optIndex + 1 }}){{ optItem.name }} * {{ optItem.num }}
                </p>
              </div>
            </el-popover>
          </template>
        </el-table-column> -->
        <el-table-column property="name" :label="$fanyi('备注')" width="260">
          <template slot-scope="scope">
            <el-input
              v-if="!scope.row.isAdd"
              class="addNoteBox"
              type="textarea"
              v-model="scope.row.note"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column :label="$fanyi('操作')" width="80">
          <template slot-scope="scope">
            <img
              class="delImg"
              src="../../../../../assets/user-img/commodity/delete.svg"
              alt=""
              @click="delAddGoods(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <div class="addListAddBtn">
        <button @click="addGoods">{{ $fanyi("添加一行") }}</button>
      </div>
      <div class="saveBtn">
        <button @click="saveGoodsData">{{ $fanyi("保存") }}</button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addList: [],
      oldGoodsCheckList: [],
    };
  },
  watch: {
    "$parent.addDiaStuts"() {
      if (this.$parent.addDiaStuts == false) {
        this.addList = [];
      }
    },
  },
  created() {},
  methods: {
    // 添加到商品库
    saveGoodsData() {
      // return //console.log(this.addList);
      // 将数据转为字符串
      let addData = JSON.parse(JSON.stringify(this.addList));
      addData.forEach((addListItem, addListIndex) => {
        addListItem.price_range = JSON.stringify(addListItem.price_range);
        addListItem.goods_inventory = JSON.stringify(
          addListItem.goods_inventory
        );
        addListItem.specification = JSON.stringify(
          this.oldGoodsCheckList[addListIndex]
        );
        addListItem.specification_checked = JSON.stringify(
          addListItem.specification_checked
        );
        addListItem.detailChinese = ((list) => {
          //console.log(list);
          let checkdata = [];
          list.specificationChinese.forEach((specificItem, specificIndex) => {
            checkdata.push({
              key: specificItem.key,
              value: list.specification_checked_chinese[specificIndex],
            });
          });
          return checkdata;
        })(addListItem);
        addListItem.specification_checked = addListItem.detailChinese;
        delete addListItem.goods_url;
        delete addListItem.price;
        addListItem.option = JSON.stringify(addListItem.option);
        addListItem.specification_checked_chinese = JSON.stringify(
          addListItem.specification_checked_chinese
        );
        if (!addListItem.note) delete addListItem.note;
        delete addListItem.goods_inventoryChinese;
        delete addListItem.specification_checked_chinese;
        delete addListItem.specification;
        delete addListItem.specificationChinese;
        delete addListItem.goods_inventory;
      });
      // return //console.log(addData);

      addData = JSON.stringify(addData);

      // 添加
      this.$api
        .favoriteInventoryBatchSave({
          data: addData,
        })
        .then((res) => {
          if (res.code != 0) return;

          this.$parent.addDiaStuts = false;
          const loading = this.$loading({
            lock: true,
            text: this.$fanyi("保存中"),
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          setTimeout(() => {
            loading.close();
            this.$parent.getData();
          }, 3000);
        });
    },
    //   数据处理
    dataProcessing(addData) {
      addData.forEach((item, nnuiindex) => {
        this.oldGoodsCheckList.push(
          JSON.parse(JSON.stringify(item.specification))
        );

        item.goods_inventory = JSON.parse(item.goods_inventory);
        item.option = JSON.parse(item.option);
        item.specification_checked = JSON.parse(item.specification_checked);
        item.specification = JSON.parse(item.specification);
        item.specification_checked_chinese = item.specification_checked;
        item.specification = ((data) => {
          let skuProps = [];
          Object.keys(data.specification).forEach((key) => {
            skuProps.push({
              key: key.substr(1, key.length - 2),
              value: data.specification[key],
              active: 0,
            });
          });
          return skuProps;
        })(item);
        item.specificationChinese = JSON.parse(item.specificationChinese);
        item.specificationChinese = ((data) => {
          let skuProps = [];
          Object.keys(data.specificationChinese).forEach((key) => {
            skuProps.push({
              key: key.substr(1, key.length - 2),
              value: data.specificationChinese[key],
              active: 0,
            });
          });
          return skuProps;
        })(item);
        // 如果接口有返回已选中的选项，则选中返回的选择，否则选中第一项,并把第一项的选项填充到已选中选项数组里
        item.specification.forEach((ctem, cndex) => {
          if (item.specification_checked[cndex]) {
            let act = 0;
            ((str) => {
              ctem.value.forEach((ctemValue, ctemIndex) => {
                if (ctemValue.name == str) {
                  act = ctemIndex;
                }
              });
            })(item.specification_checked[cndex]);
            ctem.active = act;
          } else {
            item.specification_checked[cndex] = ctem.value[ctem.active].name;
          }
          item.specification_checked_chinese[cndex] =
            item.specificationChinese[cndex].value[0].name;
        });
        //console.log(item, nnuiindex);
        this.getPrice(item.specification_checked.join("㊖㊎"), nnuiindex, item);
      });
    },
    // 根据选择条件匹配价格
    getPrice(str, tabDaIndex, adddata) {
      //console.log(adddata);
      // //console.log(typeof adddata.goods_inventoryChinese);

      str = '"' + str + '"';
      let goods_inventoryChinese =
        typeof adddata.goods_inventoryChinese == "string"
          ? JSON.parse(adddata.goods_inventoryChinese)
          : adddata.goods_inventoryChinese;
      adddata.price = goods_inventoryChinese[str].price;
    },
    // 商品规格选择,传入参数：单个商品的选项选中的序号，单个商品数据的选项数据
    getColor(
      active,
      detail,
      detailIndex,
      goodsSpecification_checked,
      goodsIndex,
      specification_checked_chinese,
      specificationChinese,
      row
    ) {
      detail.active = active;
      goodsSpecification_checked[detailIndex] = detail.value[active].name;
      specification_checked_chinese[detailIndex] = detail.value[active].name;
      this.$forceUpdate();

      //console.log(specificationChinese[detailIndex].value[active].name);
      goodsSpecification_checked[detailIndex] =
        specificationChinese[detailIndex].value[active].name;

      this.getPrice(goodsSpecification_checked.join("㊖㊎"), goodsIndex, row);
      this.$forceUpdate();
    },
    saveOrderOption(item, goodsIndex) {
      item.option = JSON.parse(JSON.stringify(this.$parent.optionList));
      this.$api
        .favoriteInventoryOption({
          id: item.id,
          option: JSON.stringify(item.option),
        })
        .then((res) => {
          if (res.code != 0) return this.message.error(res.msg);
        });

      this.$forceUpdate();
    },
    // 设置附加服务-打开弹窗并初始化数据和选中已选附加服务
    setOrderOption(item) {
      this.$parent.optionList = JSON.parse(JSON.stringify(item));
      this.$forceUpdate();
      // this.showOptionList = true;
    },
    // 删除商品
    delAddGoods(item) {
      let ids = item.id;
      this.$parent.$refs.dialog.open(
        this.$fanyi("是否删除选中的商品") + "？",
        () => {
          this.addList.splice(this.addList.indexOf(item), 1);
        },
        () => {}
      );
    },
    // 添加新商品
    addGoods() {
      this.addList.push({
        goods_url: "",
        isAdd: true,
      });
    },
    // 搜索商品信息
    searchGoods(item, index) {
      let goods_url = JSON.parse(JSON.stringify(item.goods_url));
      this.$api
        .favoriteInventoryGetGoods({
          goods_url: item.goods_url,
        })
        .then((res) => {
          if (res.code && res.code == 10000) return;
          this.addList.splice(index, 1, res);
          // this.addList[(index.isAdd = false)];
          this.dataProcessing([this.addList[index]]);
          this.addList[index].goods_url = goods_url;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
  border-radius: 6px;
  .addImg {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    border: 1px solid #ededed;
  }
  .addTextarea {
    textarea {
      width: 300px;
      height: 80px;
      border-radius: 4px;
      border: 1px solid #ededed;
    }
  }
}
.el-table {
  margin-bottom: 30px;
}
/deep/.el-table td.el-table__cell {
  border: none !important;
}
.goodsDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .detail {
    flex: 1;

    height: 80px;
    .detailCon {
      width: 100%;
      p {
        width: 100px;
        text-align: left;
      }
    }
  }
  img {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }
  .detailChoice {
    position: absolute;
    top: 0px;
    width: 420px;
    padding: 20px;
    background: red;
    border: solid;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  }
}
.chioceDetail {
  max-height: 500px;
  overflow-y: auto;
  > .dia {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    .chioceTitle {
      display: block;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #000000;
      line-height: 18px;
    }
    .chioceBody {
      display: block;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .chioceBodyOpt {
        margin-right: 10px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 16px;

        border: 1px solid #cccccc;
        padding: 7px 8px;
        margin-bottom: 20px;
        cursor: pointer;
        &.active {
          color: #ffa724;
          border: 1px solid #ffa724;
        }
      }
    }
  }
}
/deep/.addNoteBox {
  textarea {
    width: 240px;
    height: 80px;
    border-radius: 4px;
    border: 1px solid #ededed;
  }
}
.delImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.addListAddBtn {
  margin-bottom: 30px;
  button {
    width: 146px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ffa724;
    background-color: transparent;
    color: #ffa724;
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
}
.tabcellClassName {
}
/deep/.tabcellHeadClassName {
  th {
    background: #f0f0f0;
    font-size: 14px;
    color: #000000;
    line-height: 19px;
  }
}
.trueBtn {
  text-align: right;
  .el-button {
    width: 120px;
    height: 40px;
    border-radius: 4px;
  }
}
.saveBtn {
  text-align: right;
  button {
    width: 100px;
    height: 40px;
    background: #1e2997;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    line-height: 20px;
  }
}
</style>
